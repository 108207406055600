import React, { useCallback } from "react";
import { ResidentialInformationFormPresentation } from "./ResidentialInformationForm.presentation";
import { ResidentialInformationFormType } from "./ResidentialInformationForm.types";
import { useForm } from "react-hook-form";
import { CreatePropertySteps, PropertyType } from "../../UpsertProperty.types";
import { useStatics } from "../../../../api/modules/Statics";
import { format, parse } from "date-fns";
import { DATE_FORMAT } from "../../../../constants/global";

type Props = {
  onPreserveData: (
    data: Partial<PropertyType>,
    shouldSendRequest?: boolean
  ) => void;
  onSetCurrentStep: (step: CreatePropertySteps) => void;
  persistedPropertyData: Partial<PropertyType>;
  isCreating: boolean;
};
export const ResidentialInformationForm = (props: Props) => {
  const {
    onPreserveData,
    onSetCurrentStep,
    persistedPropertyData,
    isCreating,
  } = props;
  const { data: statics } = useStatics();
  const { residentialCapacityOptions } = statics || {};
  const defaultResidentialCapacity = Number(
    persistedPropertyData?.residentialCapacity || "1"
  ).toString();
  const {
    register,
    handleSubmit,
    formState: { isValid, touchedFields, errors },
    control,
    watch,
  } = useForm<ResidentialInformationFormType>({
    mode: "onChange",
    defaultValues: {
      // @ts-expect-error
      residentialInfoLastDateOfOccupancy: persistedPropertyData[
        "residentialInfoLastDateOfOccupancy"
      ]
        ? parse(
            persistedPropertyData["residentialInfoLastDateOfOccupancy"],
            DATE_FORMAT,
            new Date()
          )
        : undefined,
      residentialInfoNumberOfCurrentResidents:
        persistedPropertyData["residentialInfoNumberOfCurrentResidents"],
      residentialCapacity: defaultResidentialCapacity,
      residentialInfoGdpRange: persistedPropertyData["residentialInfoGdpRange"],
    },
  });
  const residentialCapacity = watch("residentialCapacity");
  const onSubmit = handleSubmit((data) => {
    const payload: Partial<PropertyType> = {
      residentialCapacity: data.residentialCapacity
        ? Number(data.residentialCapacity)
        : null,
      residentialInfoNumberOfCurrentResidents:
        data.residentialInfoNumberOfCurrentResidents,
      residentialInfoGdpRange: data.residentialInfoGdpRange,
      residentialInfoLastDateOfOccupancy:
        data.residentialInfoLastDateOfOccupancy
          ? format(
              data.residentialInfoLastDateOfOccupancy as unknown as Date,
              DATE_FORMAT
            )
          : undefined,
    };
    onPreserveData(payload);
    onSetCurrentStep(CreatePropertySteps.PropertyContacts);
  });

  const onCancel = useCallback(() => {
    onSetCurrentStep(CreatePropertySteps.PropertyUse);
  }, [onSetCurrentStep]);

  return (
    <ResidentialInformationFormPresentation
      control={control}
      defaultResidentialCapacity={defaultResidentialCapacity}
      errors={errors}
      isLoading={isCreating}
      isValid={isValid}
      register={register}
      residentialCapacity={(residentialCapacity || 0).toString()}
      residentialCapacityOptions={residentialCapacityOptions}
      touchedFields={touchedFields}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};
