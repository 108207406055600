import React from "react";
import { Button, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { PresentationProps } from "./PropertyUseForm.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";

export const PropertyUseFormPresentation = (props: PresentationProps) => {
  const screen = translations.screens.upsertProperty.propertyType;
  const { isValid, register, defaultPropertyUse, onSubmit, onCancel } = props;
  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column" maxWidth={["100%", "100%", "100%", "65%"]}>
        <Text fontSize="lg" fontWeight={700}>
          {t<string>(screen.title)}
        </Text>
        <Flex mt={3}>
          <Text fontSize="md" fontWeight={500}>
            {t<string>(screen.type)}
          </Text>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection={["column", "column", "column", "row"]}
          flexGrow={1}
          flexWrap="wrap"
          gap="10px"
          mt={3}
          width="100%"
        >
          <RadioGroup
            as={Flex}
            defaultValue={defaultPropertyUse}
            flexDirection="column"
          >
            <Radio
              value="1"
              {...register("propertyUse", {
                required: "Value is required",
              })}
            >
              {t<string>(screen.yes)}
            </Radio>
            <Radio
              value="0"
              {...register("propertyUse", {
                required: "Value is required",
              })}
            >
              {t<string>(screen.no)}
            </Radio>
          </RadioGroup>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={7}>
          <Button size="sm" type="button" onClick={onCancel}>
            {t<string>(screen.cancelLabel)}
          </Button>
          <Button
            colorScheme="blue"
            isDisabled={!isValid}
            size="sm"
            type="submit"
          >
            {t<string>(screen.submitLabel)}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
