import React from "react";
import Layout from "../../components/Layout";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Input,
  Portal,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Routing } from "../../constants/routing";
import { translations } from "../../i18n/translations";
import { PresentationProps } from "./Profile.types";
import { Link as RouterLink } from "react-router-dom";
import FullPageContent from "../../components/FullPageContent";
import { PersonHeader } from "../../components/PersonHeader";
import { PHONE_PATTERN } from "../../constants/global";
import { PatternFormat } from "react-number-format";

export const ProfilePresentation = (props: PresentationProps) => {
  const screen = translations.screens.profile;
  const {
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    isUpdatingProfile,
    data,
    isEditMode,
    onSetEditMode,
    onCancelEditMode,
    onEditField,
    onSaveProfile,
    formData,
  } = props;
  const {
    firstName,
    lastName,
    jobTitle,
    rating,
    phoneNumber,
    email,
    profilePicUrl,
  } = data || {};

  return (
    <Layout>
      <Portal containerRef={pageTitleRef}>{t<string>(screen.pageTitle)}</Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{t<string>(screen.breadcrumbs.profile)}</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>
      <FullPageContent>
        <Text fontSize="lg" fontWeight={700}>
          {t<string>(screen.pageTitle)}
        </Text>
        <br />
        {isLoading && (
          <Flex justifyContent="center" mt={4}>
            <Spinner />
          </Flex>
        )}
        {!isLoading && (
          <Flex flexDirection="column">
            <PersonHeader
              email={email}
              firstName={firstName}
              jobTitle={jobTitle}
              lastName={lastName}
              phone={phoneNumber}
              profilePicUrl={profilePicUrl}
              rating={rating}
            />
            <br />
            <Divider />
            <br />
            <Tabs>
              <TabList>
                <Tab>{t<string>(screen.personalDetails)}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <br />
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text fontSize="lg" fontWeight={600}>
                      {t<string>(screen.contactInformation)}
                    </Text>
                    <Flex justifyContent="flex-end">
                      {isEditMode && (
                        <>
                          <Button
                            colorScheme="blue"
                            isLoading={isUpdatingProfile}
                            size="sm"
                            variant="outline"
                            onClick={onCancelEditMode}
                          >
                            {t<string>(screen.cancel)}
                          </Button>
                          <Box ml={3}>
                            <Button
                              colorScheme="blue"
                              isLoading={isUpdatingProfile}
                              size="sm"
                              onClick={onSaveProfile}
                            >
                              {t<string>(screen.save)}
                            </Button>
                          </Box>
                        </>
                      )}
                      {!isEditMode && (
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={onSetEditMode}
                        >
                          {t<string>(screen.edit)}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                  <br />
                  <TableContainer maxWidth="100%" width="100%">
                    <Table variant="simple">
                      <Tbody>
                        {isEditMode ? (
                          <>
                            <Tr>
                              <Td
                                fontWeight={500}
                                paddingLeft="0"
                                width="250px"
                              >
                                {t<string>(screen.table.firstName)}
                              </Td>
                              <Td color="gray.400">
                                {isEditMode ? (
                                  <Input
                                    disabled={isUpdatingProfile}
                                    value={formData.firstName}
                                    onChange={onEditField("firstName")}
                                  />
                                ) : (
                                  firstName || "-"
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td
                                fontWeight={500}
                                paddingLeft="0"
                                width="250px"
                              >
                                {t<string>(screen.table.lastName)}
                              </Td>
                              <Td color="gray.400">
                                {isEditMode ? (
                                  <Input
                                    disabled={isUpdatingProfile}
                                    value={formData.lastName}
                                    onChange={onEditField("lastName")}
                                  />
                                ) : (
                                  lastName || "-"
                                )}
                              </Td>
                            </Tr>
                          </>
                        ) : (
                          ""
                        )}
                        <Tr fontWeight={500} width="250px">
                          <Td paddingLeft="0">
                            {t<string>(screen.table.phone)}
                          </Td>
                          <Td color="gray.400">
                            {isEditMode && (
                              <PatternFormat
                                customInput={Input}
                                defaultValue={formData.phoneNumber}
                                disabled={isUpdatingProfile}
                                format={PHONE_PATTERN}
                                onValueChange={(v) => {
                                  onEditField("phoneNumber")({
                                    target: { value: v.value },
                                  } as any);
                                }}
                              />
                            )}
                            {!isEditMode && (
                              <PatternFormat
                                displayType="text"
                                format={PHONE_PATTERN}
                                value={phoneNumber}
                              />
                            )}
                          </Td>
                        </Tr>
                        <Tr fontWeight={500} width="250px">
                          <Td paddingLeft="0">
                            {t<string>(screen.table.email)}
                          </Td>
                          <Td color="gray.400">{email || "-"}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        )}
      </FullPageContent>
    </Layout>
  );
};
