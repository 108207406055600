import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { PresentationProps } from "./ResidentialInformationForm.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Controller } from "react-hook-form";
import DatePicker from "../../../../components/DatePicker";

export const ResidentialInformationFormPresentation = (
  props: PresentationProps
) => {
  const screen = translations.screens.upsertProperty.propertyInfo;
  const {
    isLoading,
    isValid,
    residentialCapacity,
    defaultResidentialCapacity,
    register,
    onSubmit,
    onCancel,
    residentialCapacityOptions,
    errors,
    control,
  } = props;
  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column" maxWidth={["100%", "100%", "100%", "65%"]}>
        <Text fontSize="lg" fontWeight={700}>
          {t<string>(screen.residentialTitle)}
        </Text>

        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexGrow={1}
          mt={4}
          width={["100%", "100%", "100%", "65%"]}
        >
          <FormControl
            isInvalid={Boolean(
              errors.residentialInfoNumberOfCurrentResidents?.message
            )}
          >
            <FormLabel htmlFor="residentialInfoNumberOfCurrentResidents">
              {t<string>(screen.residents)}
            </FormLabel>
            <Input
              id="residentialInfoNumberOfCurrentResidents"
              {...register("residentialInfoNumberOfCurrentResidents")}
            />
            <FormErrorMessage>
              {errors.residentialInfoNumberOfCurrentResidents &&
                errors.residentialInfoNumberOfCurrentResidents.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexGrow={1}
          mt={4}
          width={["100%", "100%", "100%", "65%"]}
        >
          <FormControl
            isInvalid={Boolean(
              errors.residentialInfoLastDateOfOccupancy?.message
            )}
          >
            <FormLabel htmlFor="residentialInfoLastDateOfOccupancy">
              {t<string>(screen.occupancy)}
            </FormLabel>
            <Controller
              control={control}
              name="residentialInfoLastDateOfOccupancy"
              render={({ field }) => (
                <DatePicker
                  id="residentialInfoLastDateOfOccupancy"
                  name="residentialInfoLastDateOfOccupancy"
                  selected={field.value as unknown as Date}
                  onBlur={field.onBlur}
                  onChange={(date) => field.onChange(date)}
                />
              )}
            />
            <FormErrorMessage>
              {errors.residentialInfoLastDateOfOccupancy &&
                errors.residentialInfoLastDateOfOccupancy.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mt={3}>
          <Text fontSize="md" fontWeight={500}>
            {t<string>(screen.residentialCapacity)}
          </Text>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection={["column", "column", "column", "row"]}
          flexGrow={1}
          flexWrap="wrap"
          gap="10px"
          mt={3}
          width="100%"
        >
          <RadioGroup
            as={Flex}
            defaultValue={defaultResidentialCapacity}
            flexDirection="column"
          >
            {(residentialCapacityOptions || []).map((option) => (
              <Radio
                key={option.id}
                value={option.id.toString()}
                {...register("residentialCapacity", {
                  required: "Value is required",
                })}
              >
                {option.description}
              </Radio>
            ))}
          </RadioGroup>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexGrow={1}
          mt={4}
          width={["100%", "100%", "100%", "65%"]}
        >
          <FormControl
            isInvalid={Boolean(errors.residentialInfoGdpRange?.message)}
          >
            <FormLabel htmlFor="residentialInfoGdpRange">
              {t<string>(
                screen[
                  residentialCapacity === "1"
                    ? "gdp"
                    : residentialCapacity === "2"
                    ? "gallons"
                    : "bedrooms"
                ]
              )}
            </FormLabel>
            <Input
              id="residentialInfoGdpRange"
              {...register("residentialInfoGdpRange", {
                required: "Value is required",
              })}
            />
            <FormErrorMessage>
              {errors.residentialInfoGdpRange &&
                errors.residentialInfoGdpRange.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={7}>
          <Button size="sm" type="button" onClick={onCancel}>
            {t<string>(screen.cancelLabel)}
          </Button>
          <Button
            colorScheme="blue"
            isDisabled={!isValid || isLoading}
            isLoading={isLoading}
            size="sm"
            type="submit"
          >
            {t<string>(screen.submitLabel)}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
