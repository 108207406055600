import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TData, TUpdatePropertyBody } from "./types";

export const postUpdateProperty = async (payload: TUpdatePropertyBody) => {
  const data = await client.post<TUpdatePropertyBody, TData>(
    Endpoints.postUpdateProperty,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
