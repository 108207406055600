import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postCreateProperty } from "./postCreateProperty";
import { TData, TCreatePropertyBody } from "./types";
import { translations } from "../../../i18n/translations";
import { useNavigate } from "react-router-dom";

export const useCreateProperty = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation<TData, unknown, TCreatePropertyBody>(
    (payload) => postCreateProperty(payload),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.create_property_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["properties"]);
        navigate(`/property/${data.data.propertyId}`);
      },
    }
  );
};
