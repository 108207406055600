import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { PropertyPresentation } from "./Property.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useStatics } from "../../api/modules/Statics";
import { useProperty } from "../../api/modules/Property";
import { useParams } from "react-router-dom";
import { getCreateJobLink } from "../../utils/getCreateJobLink";
import { PropertyFormData } from "./Property.types";
import { useUpdateProperty } from "../../api/modules/UpdateProperty";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../constants/global";
import { getSizeFieldPlaceholder } from "./lib/getSizeFieldPlaceholder";
import { OptionType } from "../../types/common/Api";
import { normalizeFormData } from "./lib/normalizeFormData";
import { defaultContactInfo } from "../UpsetProperty/constants";

export const Property = () => {
  const { propertyId } = useParams();
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const { mutateAsync, isLoading: isUpdatingProperty } = useUpdateProperty();
  const { isLoading: isLoadingProperties, data: propertyData } = useProperty(
    propertyId!
  );
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const handleClickCreateJob = useCallback(() => {
    if (!propertyId) return;
    location.href = getCreateJobLink(propertyId);
  }, [propertyId]);

  const commercialCapacityOptions: OptionType[] = [
    { label: "-", value: "" },
  ].concat(
    (staticsData?.commercialCapacityOptions || []).map(
      ({ id, description }) => ({ label: description, value: id.toString() })
    )
  );
  const residentialCapacityOptions: OptionType[] = [
    { label: "-", value: "" },
  ].concat(
    (staticsData?.residentialCapacityOptions || []).map(
      ({ id, description }) => ({ label: description, value: id.toString() })
    )
  );

  const [formData, setFormData] = useState<PropertyFormData>({
    propertyId: propertyId ? +propertyId : 0,
    residentialInfoNumberOfBedrooms: "",
    residentialInfoNumberOfCurrentResidents: "",
    commercialInfoTypeOfEstablishment: "",
    propertyUse: false,
    residentialInfoLastDateOfOccupancy: null,
    commercialInfoLastDateOfUse: null,
    residentialCapacity: 0,
    commercialCapacity: 0,
    commercialInfoGdpRange: "",
    residentialInfoGdpRange: "",
    propertyContacts: [],
  });
  const handleAddContact = useCallback(() => {
    setFormData((data) => ({
      ...data,

      propertyContacts: [
        ...(data.propertyContacts || []),
        ...[{ ...defaultContactInfo }],
      ],
    }));
  }, [setFormData]);

  const handleRemoveContact = useCallback(
    (index: number) => () => {
      setFormData((data) => {
        return {
          ...data,
          propertyContacts: (data.propertyContacts || []).filter(
            (c, i) => i !== index
          ),
        };
      });
    },
    [setFormData]
  );

  const handleSetEditMode = useCallback(() => {
    if (!propertyData) return;
    setIsEditMode(true);

    setFormData({
      propertyId: propertyData.propertyId,
      residentialInfoNumberOfBedrooms:
        propertyData.residentialInfoNumberOfBedrooms,
      residentialInfoNumberOfCurrentResidents:
        propertyData.residentialInfoNumberOfCurrentResidents,
      commercialInfoTypeOfEstablishment:
        propertyData.commercialInfoTypeOfEstablishment,
      propertyUse: propertyData.propertyUse,
      residentialInfoLastDateOfOccupancy:
        propertyData.residentialInfoLastDateOfOccupancy,
      commercialInfoLastDateOfUse: propertyData.commercialInfoLastDateOfUse,
      residentialCapacity: propertyData.residentialCapacity,
      commercialCapacity: propertyData.commercialCapacity,
      commercialInfoGdpRange: propertyData.commercialInfoGdpRange,
      residentialInfoGdpRange: propertyData.residentialInfoGdpRange,
      propertyContacts: propertyData.propertyContacts,
    });
  }, [setIsEditMode, setFormData, propertyData]);

  const handleCancelEditMode = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);

  const handleEditField = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((state) => ({ ...state, [field]: e.target.value }));
      },
    [setFormData]
  );
  const handleEditContactField = useCallback(
    (field: string, index: number) =>
      (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData((state) => ({
          ...state,
          propertyContacts: (state.propertyContacts || []).map((c, i) => {
            if (index !== i) return c;

            return { ...c, [field]: e.target.value };
          }),
        }));
      },
    [setFormData]
  );
  const handleChangeRadioBoolean = useCallback(
    (field: string) => (value: string) => {
      setFormData((state) => ({ ...state, [field]: Boolean(+value) }));
    },
    [setFormData]
  );

  const handleChangeDate = useCallback(
    (field: string) => (value: Date) => {
      setFormData((state) => ({
        ...state,
        [field]: value ? format(value, DATE_FORMAT) : null,
      }));
    },
    [setFormData]
  );

  const handleSaveProperty = useCallback(async () => {
    await mutateAsync(normalizeFormData(formData));
    setIsEditMode(false);
  }, [formData, mutateAsync, setIsEditMode]);

  const commercialSizeLabel = useMemo(
    () =>
      getSizeFieldPlaceholder(
        isEditMode
          ? formData.commercialCapacity
          : propertyData?.commercialCapacity
      ),
    [formData, propertyData, isEditMode]
  );
  const residentialSizeLabel = useMemo(
    () =>
      getSizeFieldPlaceholder(
        isEditMode
          ? formData.residentialCapacity
          : propertyData?.residentialCapacity
      ),
    [formData, propertyData, isEditMode]
  );

  return (
    <PropertyPresentation
      breadcrumbsRef={breadcrumbsRef}
      commercialCapacityOptions={commercialCapacityOptions}
      commercialSizeLabel={commercialSizeLabel}
      formData={formData}
      isEditMode={isEditMode}
      isLoading={isLoadingProperties || isLoadingStatics}
      isUpdatingProperty={isUpdatingProperty}
      pageTitleRef={pageTitleRef}
      property={propertyData}
      residentialCapacityOptions={residentialCapacityOptions}
      residentialSizeLabel={residentialSizeLabel}
      statics={staticsData}
      onAddContact={handleAddContact}
      onCancelEditMode={handleCancelEditMode}
      onChangeDate={handleChangeDate}
      onChangeRadioBoolean={handleChangeRadioBoolean}
      onCreateJob={handleClickCreateJob}
      onEditContactField={handleEditContactField}
      onEditField={handleEditField}
      onRemoveContact={handleRemoveContact}
      onSaveProperty={handleSaveProperty}
      onSetEditMode={handleSetEditMode}
    />
  );
};
