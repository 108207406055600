import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { BsPlus as PlusIcon, BsTrash as RemoveIcon } from "react-icons/bs";
import { PresentationProps } from "./PropertyContactsForm.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { emailPattern } from "../../../../utils/emailPattern";
import { PatternFormat } from "react-number-format";
import { Controller } from "react-hook-form";
import { PHONE_PATTERN } from "../../../../constants/global";

export const PropertyContactsFormPresentation = (props: PresentationProps) => {
  const screen = translations.screens.upsertProperty.propertyInfo;
  const {
    isLoading,
    isValid,
    register,
    onSubmit,
    onCancel,
    errors,
    control,
    onAddContact,
    onRemoveContact,
    contacts,
    touchedFields,
  } = props;
  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column" maxWidth={["100%", "100%", "100%", "50%"]}>
        <Text fontSize="lg" fontWeight={700}>
          {t<string>(screen.propertyContacts)}
        </Text>
        {contacts.map((contact, index) => (
          <Flex
            key={contact.id}
            alignItems="flex-start"
            alignSelf="center"
            flexDirection="column"
            flexGrow={1}
            flexWrap="wrap"
            gap={3}
            justifyContent="center"
            mt={3}
            width="100%"
          >
            <Flex mt={7}>
              <Text fontWeight={500}>
                {t<string>(
                  screen[index ? "additionalContactInfo" : "contactInfo"]
                )}
              </Text>

              {Boolean(index) && (
                <Flex
                  alignItems="center"
                  ml={2}
                  style={{ cursor: "pointer" }}
                  onClick={onRemoveContact(index)}
                >
                  <RemoveIcon />
                </Flex>
              )}
            </Flex>
            <FormControl
              isInvalid={Boolean(
                errors.propertyContacts?.[index]?.firstName?.message
              )}
            >
              <FormLabel htmlFor={`propertyContacts.${index}.firstName`}>
                {t<string>(screen.contactsForm.firstName)}
              </FormLabel>
              <Input
                id={`propertyContacts.${index}.firstName`}
                {...register(`propertyContacts.${index}.firstName`, {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {errors.propertyContacts?.[index] &&
                  errors.propertyContacts?.[index]?.firstName?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={Boolean(
                errors.propertyContacts?.[index]?.lastName?.message
              )}
            >
              <FormLabel htmlFor={`propertyContacts.${index}.lastName`}>
                {t<string>(screen.contactsForm.lastName)}
              </FormLabel>
              <Input
                id={`propertyContacts.${index}.lastName`}
                {...register(`propertyContacts.${index}.lastName`, {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                {errors.propertyContacts?.[index] &&
                  errors.propertyContacts?.[index]?.lastName?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={Boolean(
                errors.propertyContacts?.[index]?.emailAddress?.message
              )}
            >
              <FormLabel htmlFor={`propertyContacts.${index}.emailAddress`}>
                {t<string>(screen.contactsForm.email)}
              </FormLabel>
              <Input
                id={`propertyContacts.${index}.emailAddress`}
                {...register(`propertyContacts.${index}.emailAddress`, {
                  required: "This is required",
                  pattern: {
                    value: emailPattern,
                    message: "Email is not valid",
                  },
                })}
              />
              <FormErrorMessage>
                {errors.propertyContacts?.[index] &&
                  errors.propertyContacts?.[index]?.emailAddress?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={Boolean(
                touchedFields.propertyContacts?.[index]?.phoneNumber &&
                  errors.propertyContacts?.[index]?.phoneNumber?.message
              )}
            >
              <FormLabel htmlFor={`propertyContacts.${index}.phoneNumber`}>
                {t<string>(screen.contactsForm.phone)}
              </FormLabel>
              <Controller
                control={control}
                name={`propertyContacts.${index}.phoneNumber`}
                render={({ field: { value, onChange } }) => (
                  <PatternFormat
                    allowEmptyFormatting
                    customInput={Input}
                    defaultValue={value}
                    format={PHONE_PATTERN}
                    id={`propertyContacts.${index}.phoneNumber`}
                    mask="_"
                    name={`propertyContacts.${index}.phoneNumber`}
                    onValueChange={(v) => {
                      onChange(v.value);
                    }}
                  />
                )}
                rules={{ required: "This is required" }}
              />
              <FormErrorMessage>
                {errors.propertyContacts?.[index] &&
                  errors.propertyContacts?.[index]?.phoneNumber?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        ))}
        <Flex justifyContent="center" my={5}>
          <Button size="sm" type="button" onClick={onAddContact}>
            <PlusIcon />
            {t<string>(screen.addContact)}
          </Button>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={7}>
          <Button size="sm" type="button" onClick={onCancel}>
            {t<string>(screen.cancelLabel)}
          </Button>
          <Button
            colorScheme="blue"
            isDisabled={!isValid || isLoading}
            isLoading={isLoading}
            size="sm"
            type="submit"
          >
            {t<string>(screen.submitLabel)}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
