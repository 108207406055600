import { Avatar, Box, Flex, Icon, Link, Text } from "@chakra-ui/react";
import React from "react";
import { t } from "i18next";
import {
  FaEnvelope as EmailIcon,
  FaPhoneAlt as PhoneIcon,
} from "react-icons/fa";
import { translations } from "../../i18n/translations";
import { getProfileImageUrl } from "../../utils/getProfileImageUrl";
import { Link as RouterLink } from "react-router-dom";
import { PatternFormat } from "react-number-format";
import { PHONE_PATTERN } from "../../constants/global";

type Props = {
  firstName: string;
  lastName: string;
  rating?: number;
  jobTitle?: string;
  email?: string;
  phone?: string;
  link?: string;
};

export const PersonCard = (props: Props) => {
  const screen = translations.common.personCard;
  const { firstName, lastName, rating, jobTitle, email, phone, link } = props;
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      mt={2}
    >
      <RouterLink to={link || ""}>
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Avatar size="xl" src={getProfileImageUrl(props)} />
          <Flex mt={5}>
            <Text fontSize="xl" fontWeight={500}>
              {`${firstName || ""} ${lastName || ""}`}
            </Text>
          </Flex>

          <Text fontSize="md">{jobTitle}</Text>
          <Flex alignItems="center" fontSize="sm">
            <Box color="yellow.400" mr={1}>
              ★
            </Box>
            <Text fontSize="sm">
              {rating || t<string>(screen.notAvailableRating)}
            </Text>
          </Flex>
        </Flex>
      </RouterLink>
      <br />
      <Flex flexDirection="column">
        <Flex>
          {email && (
            <Link href={`mailto:${email}`}>
              <Flex alignItems="center">
                <Flex mr={2}>
                  <Icon as={EmailIcon} color="gray.300" h="12px" w="12px" />
                </Flex>
                <Flex>
                  <Text fontSize="sm">{email || "-"}</Text>
                </Flex>
              </Flex>
            </Link>
          )}
        </Flex>
        <Flex mt={2}>
          {phone && (
            <Link href={`tel:${phone}`}>
              <Flex alignItems="center">
                <Flex mr={2}>
                  <Icon as={PhoneIcon} color="gray.300" h="12px" w="12px" />
                </Flex>
                <Flex>
                  <Text fontSize="sm">
                    <PatternFormat
                      displayType="text"
                      format={PHONE_PATTERN}
                      value={phone}
                    />
                  </Text>
                </Flex>
              </Flex>
            </Link>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
