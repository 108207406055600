import { TUserCompany } from "../../api/modules/User/types";

export const getAddressCopy = (data?: TUserCompany) => {
  if (!data) return "-";
  const { zip, addressLine1, addressLine2, city, state } = data;

  return `${addressLine1 || ""} ${addressLine2 || ""} ${city || ""} ${
    state || ""
  } ${zip || ""} `;
};
