import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TData, TCreatePropertyBody } from "./types";

export const postCreateProperty = async (payload: TCreatePropertyBody) => {
  const data = await client.post<TCreatePropertyBody, TData>(
    Endpoints.postCreateProperty,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
