import { Avatar, Box, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { t } from "i18next";
import { translations } from "../../i18n/translations";
import { getProfileImageUrl } from "../../utils/getProfileImageUrl";

type Props = {
  firstName?: string | null;
  lastName?: string | null;
  rating?: number;
  jobTitle?: string;
  link?: string;
  email?: string;
  profilePicUrl?: string | null;
  phone?: string | null;
};

export const PersonHeader = (props: Props) => {
  const screen = translations.common.personCard;
  const { firstName, lastName, rating, jobTitle, email, profilePicUrl } = props;
  return (
    <Flex alignItems="center">
      <Avatar size="lg" src={getProfileImageUrl({ email, profilePicUrl })} />
      <VStack
        alignItems="flex-start"
        display={{ base: "none", md: "flex" }}
        ml="3"
        spacing="1px"
      >
        <Text fontSize="lg" fontWeight={500}>
          {`${firstName || ""} ${lastName || ""}`}
        </Text>
        <Text color="gray.500" fontSize="sm">
          {jobTitle}
        </Text>
        <Flex alignItems="center" fontSize="sm">
          <Box color="yellow.400" mr={1}>
            ★
          </Box>
          <Text fontSize="sm">
            {rating || t<string>(screen.notAvailableRating)}
          </Text>
        </Flex>
      </VStack>
    </Flex>
  );
};
