import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Portal,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { PresentationProps } from "./User.types";
import { translations } from "../../i18n/translations";
import Layout from "../../components/Layout";
import { Routing } from "../../constants/routing";
import { PersonalDetails } from "./Components/PersonalDetails";
import { CompanyDetails } from "./Components/CompanyDetails";
import { Link as RouterLink } from "react-router-dom";
import { ContactModal } from "./Components/ContactModal";
import FullPageContent from "../../components/FullPageContent";
import { PersonHeader } from "../../components/PersonHeader";
import { ServiceDetails } from "./Components/ServiceDetails";

export const UserPresentation = (props: PresentationProps) => {
  const screen = translations.screens.user;
  const {
    serviceOptions,
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    data,
    isContactModalOpen,
    onContactModalClose,
    onContactModalOpen,
  } = props;
  const {
    firstName,
    lastName,
    jobTitle,
    rating,
    company,
    email,
    profilePicUrl,
    phoneNumber,
  } = data || {};

  return (
    <>
      <Layout>
        <Portal containerRef={pageTitleRef}>
          {t<string>(screen.pageTitle)}
        </Portal>
        <Portal containerRef={breadcrumbsRef}>
          <Breadcrumb fontWeight={500}>
            <BreadcrumbItem color="gray.400">
              <BreadcrumbLink as={RouterLink} to={Routing.Root}>
                {t<string>(screen.breadcrumbs.root)}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <div>{t<string>(screen.breadcrumbs.profile)}</div>
            </BreadcrumbItem>
          </Breadcrumb>
        </Portal>
        <FullPageContent>
          <Text fontSize="lg" fontWeight={700}>
            {t<string>(screen.pageTitle)}
          </Text>
          <br />
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}
          {!isLoading && (
            <Flex flexDirection="column">
              <Flex alignItems="center" justifyContent="space-between">
                <PersonHeader
                  email={email}
                  firstName={firstName}
                  jobTitle={jobTitle}
                  lastName={lastName}
                  phone={phoneNumber}
                  profilePicUrl={profilePicUrl}
                  rating={rating}
                />
                <Flex>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={onContactModalOpen}
                  >
                    {t<string>(screen.contact)}
                  </Button>
                </Flex>
              </Flex>
              <br />
              <Divider />
              <br />
              <Tabs>
                <TabList>
                  <Tab>{t<string>(screen.personalDetails)}</Tab>
                  <Tab>{t<string>(screen.serviceDetails)}</Tab>
                  <Tab>{t<string>(screen.companyInformation)}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <PersonalDetails data={data} />
                  </TabPanel>
                  <TabPanel>
                    <ServiceDetails serviceOptions={serviceOptions} />
                  </TabPanel>
                  <TabPanel>
                    <CompanyDetails data={company} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          )}
        </FullPageContent>
      </Layout>
      <ContactModal
        isOpen={isContactModalOpen}
        userData={data}
        onClose={onContactModalClose}
      />
    </>
  );
};
