import React, { forwardRef } from "react";
import DatePickerComponent from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Props } from "./DatePicker.types";
import { Input, InputProps } from "@chakra-ui/react";

export const DatePicker = (props: Props) => {
  const {
    name,
    // label,
    selected,
    // isTouched,
    // errorMessage,
    onChange,
    id,
    ...otherProps
  } = props;

  const DateInput = forwardRef<
    HTMLInputElement,
    Pick<
      InputProps,
      "name" | "onChange"
      // "name" | "label" | "onChange" | "errorMessage" | "isTouched"
    >
  >(({ name: _, ...p }, ref) => <Input ref={ref} name={name} {...p} />);
  DateInput.displayName = "DateInput";

  return (
    <DatePickerComponent
      customInput={
        <DateInput
          // errorMessage={errorMessage}
          // isTouched={isTouched}
          // label={label}
          name={name}
          {...otherProps}
        />
      }
      id={id}
      selected={selected}
      onChange={onChange}
    />
  );
};
