import {
  MdCorporateFare as CommercialIcon,
  MdHome as ResidentialIcon,
} from "react-icons/md";
import { Flex, FlexProps, Icon } from "@chakra-ui/react";
import React from "react";

type Props = {
  propertyUse: boolean;
} & FlexProps;

export const PropertyIcon = (props: Props) => {
  const { propertyUse, ...flexProps } = props;
  const IconComponent = propertyUse ? CommercialIcon : ResidentialIcon;
  return (
    <Flex
      alignItems="center"
      backgroundColor="gray.100"
      borderRadius="5px"
      flexShrink={0}
      height="25px"
      justifyContent="center"
      width="25px"
      {...flexProps}
    >
      <Icon as={IconComponent} color="gray.600" />
    </Flex>
  );
};
