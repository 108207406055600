import React, { useCallback, useContext, useEffect } from "react";
import { JobsPresentation } from "./Jobs.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useJobs } from "../../api/modules/Jobs";
import { useStatics } from "../../api/modules/Statics";
import { usePagination } from "../../hooks/usePagination";
import { useNavigate } from "react-router-dom";
import { getMarketingUrl } from "../../utils/getMarketingUrl";

export const Jobs = () => {
  const navigate = useNavigate();
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const pagination = usePagination();
  const { isLoading: isLoadingJobs, data: jobsData } = useJobs({
    itemsPerPage: pagination.itemsPerPage,
    currentPage: pagination.currentPage,
  });

  useEffect(() => {
    pagination.setTotalItems(jobsData?.totalJobsCount ?? 0);
  }, [pagination, jobsData?.totalJobsCount]);

  const handleClickJob = useCallback(
    (jobId: string | number) => () => {
      navigate(`/job/${jobId}`);
    },
    [navigate]
  );
  const handleClickUser = useCallback(
    (userId: string | number) => () => {
      navigate(`/user/${userId}`);
    },
    [navigate]
  );

  const handleAddNewJob = useCallback(() => {
    window.location.assign(getMarketingUrl());
  }, []);

  return (
    <JobsPresentation
      breadcrumbsRef={breadcrumbsRef}
      isLoading={isLoadingJobs || isLoadingStatics}
      jobsData={jobsData}
      pageTitleRef={pageTitleRef}
      pagination={pagination}
      statics={staticsData}
      onAddNewJob={handleAddNewJob}
      onClickJob={handleClickJob}
      onClickUser={handleClickUser}
    />
  );
};
