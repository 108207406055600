import { getBaseUrl } from "../../utils/getBaseUrl";
import { getAccessToken } from "../../utils/getAccessToken";

async function http<T>(path: string, config: RequestInit): Promise<T> {
  const baseUrl = getBaseUrl();
  const url = path.startsWith("http") ? path : `${baseUrl}${path}`;
  const token = getAccessToken();
  const headers: HeadersInit = token
    ? { Authorization: `Bearer ${token}` }
    : {};
  const request = new Request(url, {
    ...config,
    headers: {
      ...headers,
      ...config.headers,
    },
  });
  const response = await fetch(request);

  if (!response.ok) {
    const errorMsg = (await response.json())?.status?.message;
    throw new Error(errorMsg || `${response.status} ${response.statusText}`);
  }

  return response.json().catch(() => ({}));
}

export async function get<T>(path: string, config?: RequestInit): Promise<T> {
  const init = { method: "get", ...config };
  return http<T>(path, init);
}

export async function post<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = { method: "post", body: JSON.stringify(body), ...config };
  return http<U>(path, init);
}

export async function put<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = { method: "put", body: JSON.stringify(body), ...config };
  return http<U>(path, init);
}

export async function getFile(path: string) {
  try {
    const baseUrl = getBaseUrl();
    const token = getAccessToken();
    const headers: HeadersInit = token
      ? { Authorization: `Bearer ${token}` }
      : {};
    const url = path.startsWith("http") ? path : `${baseUrl}${path}`;

    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error downloading file:", error);
    return null;
  }
}
