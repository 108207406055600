import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { PresentationProps } from "./CommercialInformationForm.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Controller } from "react-hook-form";
import DatePicker from "../../../../components/DatePicker";

export const CommercialInformationFormPresentation = (
  props: PresentationProps
) => {
  const screen = translations.screens.upsertProperty.propertyInfo;
  const {
    isLoading,
    isValid,
    commercialCapacity,
    defaultCommercialCapacity,
    register,
    onSubmit,
    onCancel,
    commercialCapacityOptions,
    errors,
    control,
    // touchedFields,
  } = props;
  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column" maxWidth={["100%", "100%", "100%", "65%"]}>
        <Text fontSize="lg" fontWeight={700}>
          {t<string>(screen.commercialTitle)}
        </Text>

        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexGrow={1}
          mt={4}
          width={["100%", "100%", "100%", "65%"]}
        >
          <FormControl
            isInvalid={Boolean(
              errors.commercialInfoTypeOfEstablishment?.message
            )}
          >
            <FormLabel htmlFor="commercialInfoTypeOfEstablishment">
              {t<string>(screen.type)}
            </FormLabel>
            <Input
              id="commercialInfoTypeOfEstablishment"
              placeholder={t<string>(screen.typePlaceholder)}
              {...register("commercialInfoTypeOfEstablishment")}
            />
            <FormErrorMessage>
              {errors.commercialInfoTypeOfEstablishment &&
                errors.commercialInfoTypeOfEstablishment.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexGrow={1}
          mt={4}
          width={["100%", "100%", "100%", "65%"]}
        >
          <FormControl
            isInvalid={Boolean(errors.commercialInfoLastDateOfUse?.message)}
          >
            <FormLabel htmlFor="commercialInfoLastDateOfUse">
              {t<string>(screen.occupancy)}
            </FormLabel>
            <Controller
              control={control}
              name="commercialInfoLastDateOfUse"
              render={({ field }) => (
                <DatePicker
                  id="commercialInfoLastDateOfUse"
                  name="commercialInfoLastDateOfUse"
                  selected={field.value as unknown as Date}
                  onBlur={field.onBlur}
                  onChange={(date) => field.onChange(date)}
                />
              )}
            />
            <FormErrorMessage>
              {errors.commercialInfoLastDateOfUse &&
                errors.commercialInfoLastDateOfUse.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex mt={3}>
          <Text fontSize="md" fontWeight={500}>
            {t<string>(screen.commercialCapacity)}
          </Text>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection={["column", "column", "column", "row"]}
          flexGrow={1}
          flexWrap="wrap"
          gap="10px"
          mt={3}
          width="100%"
        >
          <RadioGroup
            as={Flex}
            defaultValue={defaultCommercialCapacity}
            flexDirection="column"
          >
            {(commercialCapacityOptions || []).map((option) => (
              <Radio
                key={option.id}
                value={option.id.toString()}
                {...register("commercialCapacity", {
                  required: "Value is required",
                })}
              >
                {option.description}
              </Radio>
            ))}
          </RadioGroup>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          flexGrow={1}
          mt={4}
          width={["100%", "100%", "100%", "65%"]}
        >
          <FormControl
            isInvalid={Boolean(errors.commercialInfoGdpRange?.message)}
          >
            <FormLabel htmlFor="commercialInfoGdpRange">
              {t<string>(
                screen[commercialCapacity === "1" ? "gdp" : "gallons"]
              )}
            </FormLabel>
            <Input
              id="commercialInfoGdpRange"
              {...register("commercialInfoGdpRange", {
                required: "Value is required",
              })}
            />
            <FormErrorMessage>
              {errors.commercialInfoGdpRange &&
                errors.commercialInfoGdpRange.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={7}>
          <Button size="sm" type="button" onClick={onCancel}>
            {t<string>(screen.cancelLabel)}
          </Button>
          <Button
            colorScheme="blue"
            isDisabled={!isValid || isLoading}
            isLoading={isLoading}
            size="sm"
            type="submit"
          >
            {t<string>(screen.submitLabel)}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
