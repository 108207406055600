import React from "react";
import { Flex, Link, Text } from "@chakra-ui/react";
import { t } from "i18next";

import { Link as RouterLink, Outlet } from "react-router-dom";
import { translations } from "../../../i18n/translations";
import { Routing } from "../../../constants/routing";
import { SideMenuWrapper } from "../../../components/SideMenuWrapper";
import Layout from "../../../components/Layout";

type Props = {
  onLogout: () => void;
};

export const SettingsWrapperPresentation = (props: Props) => {
  const { onLogout } = props;
  const screen = translations.screens.settings;
  const sideContent = (
    <>
      <Text fontWeight={600}>{t<string>(screen.menu.settings)}</Text>
      <Flex flexDirection={["row", "row", "row", "column"]}>
        <Flex mt={4}>
          <Link
            as={RouterLink}
            color="gray.400"
            fontSize="sm"
            to={Routing.Settings.LoginInformation.Absolute}
          >
            {t<string>(screen.menu.loginInformation)}
          </Link>
        </Flex>
        <Flex ml={[3, 3, 3, 0]} mt={4}>
          <Link color="gray.400" fontSize="sm" onClick={onLogout}>
            {t<string>(screen.menu.logout)}
          </Link>
        </Flex>
      </Flex>
    </>
  );
  return (
    <Layout>
      <SideMenuWrapper sideContent={sideContent}>
        <Outlet />
      </SideMenuWrapper>
    </Layout>
  );
};
