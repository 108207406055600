import { IconType } from "react-icons";
import {
  IoIosSettings,
  // IoIosCube,
  // IoMdHelpCircle,
  IoMdPerson,
  IoIosBriefcase,
  IoMdHome,
  // IoIosText,
  // IoMdHome,
} from "react-icons/io";
import { Routing } from "./routing";

type MenuItemProps = {
  title: string;
  icon: IconType;
  link: string;
};

export const GeneralMenuItems: MenuItemProps[] = [
  // { title: "common.menu.dashboard", icon: IoIosCube, link: Routing.Root },
  { title: "common.menu.jobs", icon: IoIosBriefcase, link: Routing.Jobs },
  { title: "common.menu.properties", icon: IoMdHome, link: Routing.Properties },
  // { title: "common.menu.messages", icon: IoIosText, link: "#" },
];

export const AdditionalMenuItems: MenuItemProps[] = [
  { title: "common.menu.profile", icon: IoMdPerson, link: Routing.Profile },
  {
    title: "common.menu.settings",
    icon: IoIosSettings,
    link: Routing.Settings.LoginInformation.Absolute,
  },
  // { title: "common.menu.help", icon: IoMdHelpCircle, link: "#" },
];
