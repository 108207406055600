import { PortalsContextType } from "../../contexts/PortalsContext";
import { TStatics } from "../../api/modules/Statics/types";

export enum CreatePropertySteps {
  PropertyAddress = "propertyAddress",
  PropertyUse = "propertyUse",
  PropertyInformation = "propertyInformation",
  PropertyContacts = "propertyContacts",
}

export type PresentationProps = {
  pageTitleRef: PortalsContextType["pageTitleRef"];
  breadcrumbsRef: PortalsContextType["breadcrumbsRef"];
  isCreating: boolean;
  isLoading: boolean;
  isEditMode: boolean;
  statics?: TStatics;
  currentStep: CreatePropertySteps;
  persistedPropertyData: Partial<PropertyType>;
  onSetCurrentStep: (step: CreatePropertySteps) => void;
  onPreserveData: (
    data: Partial<PropertyType>,
    shouldSendRequest?: boolean
  ) => void;
};

export type PropertyType = {
  propertyId: number | null;
  propertyUse: boolean;
  residentialInfoNumberOfBedrooms: string;
  residentialInfoNumberOfCurrentResidents: string;
  residentialInfoLastDateOfOccupancy: string;
  commercialInfoTypeOfEstablishment: string;
  commercialCapacity: number | null;
  commercialInfoGdpRange: string;
  residentialCapacity: number | null;
  residentialInfoGdpRange: string;
  commercialInfoLastDateOfUse: string | null;
  lastPumpOutId: number;
  propertyInfoAgeOfSepticSystem: string;
  propertyInfoIsThereMoreThanOneSepticId: number;
  propertyInfoAreThereMultipleStructureId: number;
  propertyInfoSepticTankLocation: boolean;
  propertyInfoSepticSystemDesign: boolean;
  propertyInfoSeasonUse: boolean;
  projectAddressStreet: string;
  projectAddressCity: string;
  projectAddressState: string;
  projectAddressZip: string;
  homeOwnerFirstName: string;
  homeOwnerLastName: string;
  homeOwnerEmail: string;
  propertyContacts?: {
    firstName: string | null;
    lastName: string | null;
    emailAddress: string | null;
    phoneNumber: string | null;
  }[];
};
