import { TProperty } from "../../api/modules/Properties/types";

export const getAddressCopy = (
  job: Pick<
    TProperty,
    | "projectAddressZip"
    | "projectAddressStreet"
    | "projectAddressCity"
    | "projectAddressState"
  >
) => {
  const {
    projectAddressZip,
    projectAddressStreet,
    projectAddressCity,
    projectAddressState,
  } = job;

  return `${projectAddressStreet || ""} ${projectAddressCity || ""} ${
    projectAddressState || ""
  } ${projectAddressZip || ""} `;
};
