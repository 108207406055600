import { Endpoints } from "../../../constants/endpoints";
import { TJobsRaw } from "./types";
import client from "../../client";

export const getJobs =
  (currentPage: number, itemsPerPage: number) => async () => {
    const { data } = await client.get<TJobsRaw>(
      Endpoints.getJobs(currentPage, itemsPerPage)
    );
    return data;
  };
