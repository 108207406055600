import { PropertyFormData } from "../Property.types";

export const normalizeFormData = (data: PropertyFormData): PropertyFormData => {
  return {
    ...data,
    commercialCapacity:
      // @ts-ignore
      data.commercialCapacity === "" ? null : data.commercialCapacity, // api errors out when empty string is passed, however this value can be an empty string to show or hide dependent fields

    residentialCapacity:
      // @ts-ignore
      data.residentialCapacity === "" ? null : data.residentialCapacity,
  };
};
