import { TContractor } from "../../../../api/modules/Jobs/types";
import { Alert, AlertIcon, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { translations } from "../../../../i18n/translations";
import { t } from "i18next";
import { PersonCard } from "../../../../components/PersonCard";

type Props = {
  data?: TContractor | null;
};

export const JobContractor = (props: Props) => {
  const screen = translations.screens.job;
  const { data } = props;
  const { userId, firstName, lastName, rating, jobTitle, email, phoneNumber } =
    data || {};
  return (
    <Flex flexDirection="column" width="100%">
      {!data && (
        <>
          <Box color="gray.600" mb={3}>
            <Text fontSize="sm" fontWeight={500}>
              {t<string>(screen.contractor.assignedContractor)}
            </Text>
          </Box>
          <Alert borderRadius="5px" status="info">
            <AlertIcon />
            {t<string>(screen.contractor.awaitingContractor)}
          </Alert>
        </>
      )}
      {data && (
        <PersonCard
          email={email}
          firstName={firstName || ""}
          jobTitle={jobTitle}
          lastName={lastName || ""}
          link={`/user/${userId}`}
          phone={phoneNumber}
          rating={rating}
        />
      )}
    </Flex>
  );
};
