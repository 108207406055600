import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postGoogleSignIn } from "./postGoogleSignIn";
import { TData, TGoogleSignInBody } from "./types";
import { translations } from "../../../i18n/translations";
import { setAccessToken } from "../../../utils/setAccessToken";

export const useGoogleSignIn = () => {
  const toast = useToast();
  return useMutation<TData, unknown, TGoogleSignInBody>(
    (payload) => postGoogleSignIn(payload),
    {
      onError: (err) => {
        toast({
          title: "Error",
          description:
            (err as Error)?.message ||
            t<string>(translations.errors.authorization_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: (data) => {
        setAccessToken(data.accessToken);
      },
    }
  );
};
