import React from "react";
import Layout from "../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Tab,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Routing } from "../../constants/routing";
import { translations } from "../../i18n/translations";
import { PresentationProps } from "./Job.types";
import { Link as RouterLink } from "react-router-dom";
import { getAddressCopy } from "../../utils/Job/getAddressCopy";
import { JobDataTable } from "./components/JobDataTable";
import { FiChevronDown as ChevronDownIcon } from "react-icons/fi";
import FullPageContent from "../../components/FullPageContent";
import { getTagColor } from "../../utils/Job/getTagColor";
import { getJobTypeCopy } from "../../utils/Job/getJobTypeCopy";
import { PropertyDetails } from "./components/PropertyDetails";
import { JobContractor } from "./components/JobContractor";
import { JobReport } from "./components/JobReport";

export const JobPresentation = (props: PresentationProps) => {
  const screen = translations.screens.job;
  const {
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    job,
    statics,
    cancelJobModal,
  } = props;

  return (
    <Layout>
      <Portal containerRef={pageTitleRef}>{t<string>(screen.pageTitle)}</Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={Routing.Jobs}>
              {t<string>(screen.breadcrumbs.jobs)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{t<string>(screen.breadcrumbs.job)}</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>
      <FullPageContent>
        <Link
          _focus={{ boxShadow: "none" }}
          as={RouterLink}
          fontSize={12}
          fontWeight={500}
          style={{ textDecoration: "none" }}
          to={Routing.Jobs}
        >
          {t<string>(screen.backToJobs)}
        </Link>
        {isLoading && (
          <Flex justifyContent="center" mt={4}>
            <Spinner />
          </Flex>
        )}
        {!isLoading && (
          <>
            <br />
            <br />
            <Flex justifyContent="space-between">
              <Text fontSize="lg" fontWeight={700}>
                {job && getAddressCopy(job)}
              </Text>
              <Flex>
                <Menu>
                  <MenuButton
                    as={Button}
                    colorScheme="blue"
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                  >
                    {t<string>(screen.manageJob)}
                  </MenuButton>
                  <MenuList>
                    {job?.jobStatus !== "Cancelled" && (
                      <MenuItem onClick={cancelJobModal.onOpen}>
                        {t<string>(screen.cancelJob)}
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
            <Flex mb={8} mt={1}>
              {job ? (
                <Tag colorScheme={getTagColor(job.jobTypeId)}>
                  <Text size="12px">
                    {getJobTypeCopy(job, statics).jobType}
                  </Text>
                </Tag>
              ) : null}
            </Flex>
            <Divider />
            <Flex
              alignItems="stretch"
              flexDirection={["column", "column", "column", "row"]}
              gap="18px"
            >
              <Flex pt={5} width={["100%", "100%", "100%", "70%"]}>
                <Tabs width="100%">
                  <TabList>
                    <Tab>{t<string>(screen.jobDetails)}</Tab>
                    <Tab>{t<string>(screen.propertyInformation)}</Tab>
                    <Tab>{t<string>(screen.jobReport)}</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <Flex mb={1} mt={3}>
                        <Text fontSize="sm" fontWeight={600}>
                          {t<string>(screen.jobDetails)}
                        </Text>
                      </Flex>
                      <JobDataTable job={job} statics={statics} />
                    </TabPanel>
                    <TabPanel>
                      <PropertyDetails job={job} statics={statics} />
                    </TabPanel>
                    <TabPanel>
                      <JobReport
                        job={job}
                        jobId={job?.jobId}
                        status={job?.jobStatus}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
              <Flex
                borderColor="gray.200"
                borderLeftWidth="1px"
                pl={5}
                pt={5}
                width={["100%", "100%", "100%", "30%"]}
              >
                <JobContractor data={job?.contractor} />
              </Flex>
            </Flex>
          </>
        )}
      </FullPageContent>
    </Layout>
  );
};
