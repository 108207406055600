import React from "react";
import { Table, TableContainer, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./ServiceDetails.types";

export const ServiceDetailsTab = (props: Props) => {
  const { serviceOptions } = props;
  const screen = translations.screens.user;

  return (
    <>
      <br />
      <Text fontSize="lg" fontWeight={600}>
        {t<string>(screen.serviceTypes)}
      </Text>
      <br />
      {(serviceOptions || []).length ? (
        <TableContainer maxWidth="100%" width="100%">
          <Table variant="simple">
            <Tbody fontSize="sm">
              {serviceOptions.map(({ label, value }) => (
                <Tr key={value}>
                  <Td fontWeight={500} paddingLeft="0" width="250px">
                    {label}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text>{t<string>(screen.noServiceTypes)}</Text>
      )}
    </>
  );
};
