import React, { createContext, ReactNode } from "react";
import { useDisclosure } from "@chakra-ui/react";

type Props = {
  children: ReactNode;
};

export type TModalControls = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export type ModalsContextType = {
  cancelJob: TModalControls;
};

export const ModalsContext = createContext<ModalsContextType>(
  {} as ModalsContextType
);

export const ModalsContextWrapper = (props: Props) => {
  const { children } = props;
  const cancelJob = useDisclosure();

  return (
    <ModalsContext.Provider
      value={{
        cancelJob,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};
