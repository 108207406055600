import React, { useContext } from "react";
import { JobPresentation } from "./Job.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useStatics } from "../../api/modules/Statics";
import { useJob } from "../../api/modules/Job";
import { useParams } from "react-router-dom";
import { CancelJobModal } from "./components/CancelJobModal";
import { ModalsContext } from "../../contexts/ModalsContext";

export const Job = () => {
  const { jobId } = useParams();
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { isLoading: isLoadingJobs, data: jobData } = useJob(jobId!);
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { cancelJob } = useContext(ModalsContext);

  return (
    <>
      <JobPresentation
        breadcrumbsRef={breadcrumbsRef}
        cancelJobModal={cancelJob}
        isLoading={isLoadingJobs || isLoadingStatics}
        job={jobData}
        // jobsList={jobsData}
        pageTitleRef={pageTitleRef}
        statics={staticsData}
      />
      <CancelJobModal jobId={jobData?.jobId} />
    </>
  );
};
