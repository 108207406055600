import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getCompany } from "./getCompany";
import { TCompany } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";

export const useCompany = (companyId?: number | null) => {
  const toast = useToast();

  return useQuery<TCompany>(Queries.Company(companyId), getCompany(companyId), {
    staleTime: Infinity,
    cacheTime: Infinity,
    onError: () => {
      toast({
        title: "Error",
        description: t<string>(translations.errors.company_error),
        status: "error",
        position: "top-right",
      });
    },
    enabled: Boolean(companyId),
  });
};
