import React from "react";
import { Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { Props } from "./CompanyData.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { getAddressCopy } from "../../../../utils/Company/getAddressCopy";
import { PatternFormat } from "react-number-format";
import { PHONE_PATTERN } from "../../../../constants/global";

export const CompanyData = (props: Props) => {
  const screen = translations.screens.user.companyDetailsData;
  const { data } = props;
  const { companyName, businessEmail, businessPhone } = data || {};

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td fontWeight={500} paddingLeft="0" width="250px">
              {t<string>(screen.title)}
            </Td>
            <Td color="gray.500">{companyName || "-"}</Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft="0" width="250px">
              {t<string>(screen.phone)}
            </Td>
            <Td color="gray.500">
              <PatternFormat
                displayType="text"
                format={PHONE_PATTERN}
                value={businessPhone}
              />
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft="0" width="250px">
              {t<string>(screen.email)}
            </Td>
            <Td color="gray.500">{businessEmail || "-"}</Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} paddingLeft="0" width="250px">
              {t<string>(screen.companyAddress)}
            </Td>
            <Td color="gray.500">{getAddressCopy(data)}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
