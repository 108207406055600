import React from "react";
import { Text } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./CompanyDetails.types";
import { CompanyData } from "../CompanyData";

export const CompanyDetailsTab = (props: Props) => {
  const { data } = props;
  const screen = translations.screens.user;

  return (
    <>
      <br />
      <Text fontSize="lg" fontWeight={600}>
        {t<string>(screen.companyInformation)}
      </Text>
      <br />
      <CompanyData data={data} />
    </>
  );
};
