import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { ProfilePresentation } from "./Profile.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useStatics } from "../../api/modules/Statics";
import { useUser } from "../../api/modules/User";
import { ProfileFormData } from "./Profile.types";
import { useUpdateUser } from "../../api/modules/UpdateUser";

export const Profile = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { mutateAsync, isLoading } = useUpdateUser();
  const { data } = useUser();
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState<ProfileFormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const handleSetEditMode = useCallback(() => {
    setIsEditMode(true);
    setFormData({
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      phoneNumber: data?.phoneNumber || "",
      email: data?.email || "",
    });
  }, [setIsEditMode, setFormData, data]);
  const handleCancelEditMode = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);
  const handleEditField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormData((state) => ({ ...state, [field]: e.target.value }));
    },
    [setFormData]
  );
  const handleSaveProfile = useCallback(async () => {
    await mutateAsync(formData);
    setIsEditMode(false);
  }, [formData, mutateAsync, setIsEditMode]);

  return (
    <ProfilePresentation
      breadcrumbsRef={breadcrumbsRef}
      data={data}
      formData={formData}
      isEditMode={isEditMode}
      isLoading={isLoadingStatics}
      isUpdatingProfile={isLoading}
      pageTitleRef={pageTitleRef}
      statics={staticsData}
      onCancelEditMode={handleCancelEditMode}
      onEditField={handleEditField}
      onSaveProfile={handleSaveProfile}
      onSetEditMode={handleSetEditMode}
    />
  );
};
