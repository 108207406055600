import { CloseButton, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { GeneralMenuItems, AdditionalMenuItems } from "../../constants/menu";
import { Props } from "./MenuContent.types";
import MenuItem from "../MenuItem";
import Logo from "../Logo";
import { t } from "i18next";
import { translations } from "../../i18n/translations";

export const MenuContent = ({ onClose, ...rest }: Props) => {
  return (
    <Flex
      bg="gray.800"
      borderRight="1px"
      borderRightColor="gray.700"
      flexDirection="column"
      h="full"
      pos="fixed"
      transition="3s ease"
      w={{ base: "full", md: 60 }}
      {...rest}
    >
      <Flex
        alignItems="center"
        h="20"
        justifyContent="space-between"
        mb={4}
        mt={3}
        mx="8"
      >
        <Text fontFamily="monospace" fontSize="2xl" fontWeight="bold">
          <Logo width="100px" />
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Flex flexDirection="column" mx={1}>
        {GeneralMenuItems.map(({ title, icon, link }) => (
          <MenuItem key={title} icon={icon} link={link}>
            {t<string>(title)}
          </MenuItem>
        ))}
      </Flex>
      <Flex
        alignItems="flex-end"
        color="gray.500"
        flexGrow={1}
        fontSize={12}
        fontWeight={500}
        mb={5}
        mx="9"
        sx={{ textTransform: "uppercase" }}
      >
        {t<string>(translations.common.menu.more)}
      </Flex>
      <Flex flexDirection="column" mb={3} mx={1}>
        {AdditionalMenuItems.map(({ title, icon, link }) => (
          <MenuItem key={title} icon={icon} link={link}>
            {t<string>(title)}
          </MenuItem>
        ))}
      </Flex>
    </Flex>
  );
};
