import React, { useCallback } from "react";
import { PropertyUseFormPresentation } from "./PropertyUseForm.presentation";
import { PropertyUseFormType } from "./PropertyUseForm.types";
import { useForm } from "react-hook-form";
import { CreatePropertySteps, PropertyType } from "../../UpsertProperty.types";

type Props = {
  onPreserveData: (data: Pick<PropertyType, "propertyUse">) => void;
  onSetCurrentStep: (step: CreatePropertySteps) => void;
  persistedPropertyData: Partial<PropertyType>;
};
export const PropertyUseForm = (props: Props) => {
  const { onPreserveData, onSetCurrentStep, persistedPropertyData } = props;
  const defaultPropertyUse = Number(
    persistedPropertyData?.propertyUse || "0"
  ).toString();
  const {
    register,
    handleSubmit,
    formState: { isValid, touchedFields, errors },
    control,
  } = useForm<PropertyUseFormType>({
    mode: "onChange",
    defaultValues: {
      propertyUse: defaultPropertyUse,
    },
  });
  const onSubmit = handleSubmit((data) => {
    onPreserveData({ propertyUse: Boolean(+data.propertyUse) });
    onSetCurrentStep(CreatePropertySteps.PropertyInformation);
  });
  const onCancel = useCallback(() => {
    onSetCurrentStep(CreatePropertySteps.PropertyAddress);
  }, [onSetCurrentStep]);

  return (
    <PropertyUseFormPresentation
      control={control}
      defaultPropertyUse={defaultPropertyUse}
      errors={errors}
      isValid={isValid}
      register={register}
      touchedFields={touchedFields}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};
