import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { putUpdatePassword } from "./putUpdatePassword";
import { TData, TUpdatePasswordBody } from "./types";
import { translations } from "../../../i18n/translations";

export const useUpdatePassword = () => {
  const toast = useToast();
  return useMutation<TData, unknown, TUpdatePasswordBody>(
    (payload) => putUpdatePassword(payload),
    {
      onError: (error) => {
        console.error(error);
        toast({
          title: "Error",
          description: t<string>(translations.errors.update_password_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: () => {
        toast({
          title: "Success",
          description: t<string>(
            translations.screens.settings.loginInformation.updatedSuccessfully
          ),
          status: "success",
          position: "top-right",
        });
      },
    }
  );
};
