import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Routing } from "./constants/routing";
// import { Main } from "./containers/Main";
import { Login } from "./containers/Login";
import { PortalsContextWrapper } from "./contexts/PortalsContext";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import { Jobs } from "./containers/Jobs";
import { Job } from "./containers/Job";
import { Profile } from "./containers/Profile";
import { SettingsWrapper } from "./containers/Settings/SettingsWrapper";
import { LoginInformation } from "./containers/Settings/LoginInformation";
import { User } from "./containers/User";
import { theme } from "./constants/theme";
import { Properties } from "./containers/Properties";
import { Property } from "./containers/Property";
import { ForgotPassword } from "./containers/ForgotPassword";
import { ModalsContextWrapper } from "./contexts/ModalsContext";
import { UpsertProperty } from "./containers/UpsetProperty";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <ModalsContextWrapper>
        <PortalsContextWrapper>
          <BrowserRouter>
            <Routes>
              <Route element={<Login />} path={Routing.Login} />
              <Route
                element={<ForgotPassword />}
                path={Routing.ForgotPassword}
              />
              <Route element={<ProtectedRoutes />} path="/">
                <Route
                  element={<Navigate to={Routing.Jobs} />}
                  path={Routing.Root}
                />
                <Route element={<Jobs />} path={Routing.Jobs} />
                <Route element={<Job />} path={Routing.Job} />
                <Route element={<Profile />} path={Routing.Profile} />
                <Route element={<Properties />} path={Routing.Properties} />
                <Route
                  element={<UpsertProperty />}
                  path={Routing.AddProperty}
                />
                <Route element={<Property />} path={Routing.Property} />
                <Route element={<User />} path={Routing.User} />
                <Route
                  element={<SettingsWrapper />}
                  path={Routing.Settings.Root}
                >
                  <Route
                    element={<LoginInformation />}
                    path={Routing.Settings.LoginInformation.Relative}
                  />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </PortalsContextWrapper>
      </ModalsContextWrapper>
    </ChakraProvider>
  </QueryClientProvider>
);
