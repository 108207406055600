import React from "react";
import Layout from "../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Portal,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Routing } from "../../constants/routing";
import { translations } from "../../i18n/translations";
import { CreatePropertySteps, PresentationProps } from "./UpsertProperty.types";
import { Link as RouterLink } from "react-router-dom";
import { SideMenuWrapper } from "../../components/SideMenuWrapper";
import { SideMenu } from "./components/SideMenu";
import { AddressForm } from "./components/AddressForm";
import { PropertyUseForm } from "./components/PropertyUseForm";
import { CommercialInformationForm } from "./components/CommercialInformationForm";
import { ResidentialInformationForm } from "./components/ResidentialInformationForm";
import { PropertyContactsForm } from "./components/PropertyContactsForm";

export const UpsertPropertyPresentation = (props: PresentationProps) => {
  const screen = translations.screens.upsertProperty;
  const {
    currentStep,
    isEditMode,
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    isCreating,
    persistedPropertyData,
    onPreserveData,
    onSetCurrentStep,
  } = props;
  const aboveContent = (
    <Flex alignItems="flex-start" justifyContent="space-between" mb={10}>
      <Text fontSize="lg" fontWeight={700}>
        {t<string>(screen[isEditMode ? "updateTitle" : "createTitle"])}
      </Text>
    </Flex>
  );

  return (
    <Layout>
      <Portal containerRef={pageTitleRef}>
        {t<string>(screen[isEditMode ? "updateTitle" : "createTitle"])}
      </Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Properties}>
              {t<string>(screen.breadcrumbs.properties)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>
              {t<string>(screen[isEditMode ? "updateTitle" : "createTitle"])}
            </div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>

      <SideMenuWrapper
        aboveContent={aboveContent}
        sideContent={<SideMenu currentStep={currentStep} />}
      >
        <Flex flex={1} flexDirection="column">
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}
          {currentStep === CreatePropertySteps.PropertyAddress && (
            <AddressForm
              persistedPropertyData={persistedPropertyData}
              onPreserveData={onPreserveData}
              onSetCurrentStep={onSetCurrentStep}
            />
          )}
          {currentStep === CreatePropertySteps.PropertyUse && (
            <PropertyUseForm
              persistedPropertyData={persistedPropertyData}
              onPreserveData={onPreserveData}
              onSetCurrentStep={onSetCurrentStep}
            />
          )}
          {currentStep === CreatePropertySteps.PropertyInformation &&
            persistedPropertyData.propertyUse && (
              <CommercialInformationForm
                isCreating={isCreating}
                persistedPropertyData={persistedPropertyData}
                onPreserveData={onPreserveData}
                onSetCurrentStep={onSetCurrentStep}
              />
            )}
          {currentStep === CreatePropertySteps.PropertyInformation &&
            !persistedPropertyData.propertyUse && (
              <ResidentialInformationForm
                isCreating={isCreating}
                persistedPropertyData={persistedPropertyData}
                onPreserveData={onPreserveData}
                onSetCurrentStep={onSetCurrentStep}
              />
            )}
          {currentStep === CreatePropertySteps.PropertyContacts && (
            <PropertyContactsForm
              isCreating={isCreating}
              persistedPropertyData={persistedPropertyData}
              onPreserveData={onPreserveData}
              onSetCurrentStep={onSetCurrentStep}
            />
          )}
        </Flex>
      </SideMenuWrapper>
    </Layout>
  );
};
