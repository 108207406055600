import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getProperty } from "./getProperty";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";
import { TProperty } from "../Properties/types";

export const useProperty = (propertyId: string) => {
  const toast = useToast();
  return useQuery<TProperty>(
    Queries.Property(propertyId),
    getProperty(propertyId),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.property_error),
          status: "error",
          position: "top-right",
        });
      },
    }
  );
};
