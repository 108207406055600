import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { TData, TPayload } from "./types";
import { translations } from "../../../i18n/translations";
import { postCancelJob } from "./postCancelJob";
import { Queries } from "../../../constants/queries";

export const useCancelJob = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation<TData, unknown, TPayload>(
    (payload) => postCancelJob(payload),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.cancel_job_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(Queries.Job(variables.jobId.toString()));
      },
    }
  );
};
