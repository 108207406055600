export const Routing = {
  Login: "/login",
  ForgotPassword: "/forgotPassword",
  Root: "/",
  Profile: "/profile",
  Properties: "/properties",
  AddProperty: "/properties/create",
  Property: "/property/:propertyId",
  Jobs: "/jobs",
  Job: "/job/:jobId",
  User: "/user/:userId",
  Settings: {
    Root: "/settings",
    LoginInformation: {
      Absolute: "/settings/loginInformation",
      Relative: "loginInformation",
    },
  },
  Terms: "/terms",
  Privacy: "/privacy",
};
