import React from "react";
import { Divider, Flex, useToken } from "@chakra-ui/react";
import FullPageContent from "../FullPageContent";

type Props = {
  children: React.ReactNode;
  sideContent: React.ReactNode;
  aboveContent?: React.ReactNode;
};

export const SideMenuWrapper = (props: Props) => {
  const { children, sideContent, aboveContent } = props;
  const [gray100] = useToken("colors", ["gray.100"]);
  return (
    <FullPageContent>
      {aboveContent}
      <Flex flex={1} flexDirection={["column", "column", "column", "row"]}>
        <Flex mb={[3, 3, 3, 0]} width={["100%", "100%", "100%", "220px"]}>
          <Flex flexDirection="column" flexGrow={1}>
            {sideContent}
          </Flex>
          <Divider color={gray100} orientation="vertical" />
        </Flex>
        <Flex flexGrow={1} px={[0, 0, 0, 10]}>
          {children}
        </Flex>
      </Flex>
    </FullPageContent>
  );
};
