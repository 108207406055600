import { Endpoints } from "../../../constants/endpoints";
import { TPropertyRaw } from "./types";
import client from "../../client";

export const getProperty = (propertyId: string) => async () => {
  const { data } = await client.get<TPropertyRaw>(
    Endpoints.getProperty(propertyId)
  );
  return data;
};
