export const Endpoints = {
  getUser: "/api/user/profile",
  postForgotPassword: "/api/user/password/forgot",
  getUserById: (userId: string | number) => `/api/user/${userId}/profile`,
  getStatic: "/api/statics",
  getJobs: (currentPage: number, itemsPerPage: number) =>
    `/api/homeowner/jobs?pageSize=${itemsPerPage}&pageNumber=${currentPage}`,
  getProperties: (currentPage: number, itemsPerPage: number) =>
    `/api/user/property?pageSize=${itemsPerPage}&pageNumber=${currentPage}`,
  getJob: (jobId: string | number) => `/api/job/${jobId}`,
  getProperty: (propertyId: string | number) =>
    `/api/user/property/${propertyId}`,
  postAuthorizeUser: "/api/homeowner/login",
  postGoogleSignIn: "/api/homeowner/login/google",
  postAppleSignIn: "/api/homeowner/login/apple",
  putUpdateUser: "/api/user/profile",
  postUpdateProperty: "/api/user/property",
  postCreateProperty: "/api/user/property",
  postUpdatePassword: "/api/user/password",
  postCancelJob: (jobId: number) => `/api/job/${jobId}/cancel`,
  getCompany: (companyId: string | number) =>
    `/api/company?companyId=${companyId}`,
  getTitle5InspectionReport: (jobId: number) =>
    `/api/report/title5Inspection?jobId=${jobId}`,
  getSystemPumpingReport: (jobId: number) =>
    `/api/report/systemPumping?jobId=${jobId}`,
  getSepticFactsReport: (jobId: number) => `/api/report/septicFacts/${jobId}`,
  getSepticPumpoutReport: (jobId: number) =>
    `/api/job/${jobId}/pumping-info/report`,
};
