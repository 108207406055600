import {
  Avatar,
  Flex,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { TUser } from "../../../../api/modules/User/types";
import { translations } from "../../../../i18n/translations";
import { getProfileImageUrl } from "../../../../utils/getProfileImageUrl";
import {
  FaEnvelope as EmailIcon,
  FaPhoneAlt as PhoneIcon,
} from "react-icons/fa";
import { PatternFormat } from "react-number-format";
import { PHONE_PATTERN } from "../../../../constants/global";

type Props = {
  isOpen: boolean;
  userData?: TUser;
  onClose: () => void;
};

export const ContactModal = (props: Props) => {
  const { userData, onClose, isOpen } = props;
  const screen = translations.screens.user;
  const { firstName, lastName, phoneNumber, email, profilePicUrl, jobTitle } =
    userData || {};

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={10} px={10}>
            <Flex justifyContent="center">
              <Text fontSize="lg" fontWeight={700}>
                {t<string>(screen.contactContractor)}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={10} mt={3} px={12}>
            <Flex alignItems="center" flexDirection="column">
              <Avatar
                size="lg"
                src={getProfileImageUrl({ email, profilePicUrl })}
              />
              <Flex mt={5}>
                <Text fontSize="lg" fontWeight={500}>
                  {`${firstName || ""} ${lastName || ""}`}
                </Text>
              </Flex>
              <Text color="gray.500" fontSize="sm">
                {jobTitle}
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              flexDirection="column"
              fontSize="sm"
              mb={4}
              mt={7}
            >
              <Link href={`tel:${phoneNumber}`}>
                <Flex alignItems="center">
                  <Flex mr={2}>
                    <Icon as={PhoneIcon} h="14px" w="14px" />
                  </Flex>
                  <Flex>
                    <PatternFormat
                      displayType="text"
                      format={PHONE_PATTERN}
                      value={phoneNumber}
                    />
                  </Flex>
                </Flex>
              </Link>
              <Flex mt={3}>
                <Link href={`mailto:${email}`}>
                  <Flex alignItems="center">
                    <Flex mr={2}>
                      <Icon as={EmailIcon} h="14px" w="14px" />
                    </Flex>
                    <Flex>{email}</Flex>
                  </Flex>
                </Link>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
