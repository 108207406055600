import { ComponentStyleConfig } from "@chakra-ui/react";

export const Tabs: ComponentStyleConfig = {
  baseStyle: {
    tablist: {
      borderBottomWidth: "0!important",
      color: "gray.400",
    },
    tab: {
      "font-size": "14px!important",
      color: "gray.400",
    },
  },
};
