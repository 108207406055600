import { TJob } from "../../api/modules/Jobs/types";
import { TStatics } from "../../api/modules/Statics/types";

export const getScheduleCopy = (job: TJob, statics?: TStatics) => {
  const { scheduleType, flexibleDateRange, calendarDateFrom, calendarDateTo } =
    job;
  const { flexiDateRanges } = statics || {};

  if (scheduleType === "flexible") {
    return `${scheduleType[0].toUpperCase()}${scheduleType.slice(1)}: ${
      flexiDateRanges?.find((range) => range.id === flexibleDateRange)
        ?.description
    }`;
  }
  if (scheduleType === "calendar") {
    return `${calendarDateFrom} - ${calendarDateTo}`;
  }
  return "-";
};
