export const Queries = {
  User: "user",
  UserById: (userId?: string | number | null) => ["user", userId],
  Statics: "statics",
  Jobs: (currentPage: number, itemsPerPage: number) => [
    "jobs",
    currentPage,
    itemsPerPage,
  ],
  Properties: (currentPage: number, itemsPerPage: number) => [
    "properties",
    currentPage,
    itemsPerPage,
  ],
  Job: (jobId: string) => ["job", jobId],
  Property: (propertyId: string) => ["property", propertyId],
  Company: (companyId?: string | number | null) => ["company", companyId],
};
