import React from "react";
import Layout from "../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BiDotsHorizontalRounded as DotsIcon } from "react-icons/bi";
import { t } from "i18next";
import { Routing } from "../../constants/routing";
import { translations } from "../../i18n/translations";
import { PresentationProps } from "./Properties.types";
import { Link as RouterLink } from "react-router-dom";
import { getAddressCopy } from "../../utils/Job/getAddressCopy";
import { Pagination } from "../../components/Pagination";
import FullPageContent from "../../components/FullPageContent";
import { PropertyIcon } from "./components/PropertyIcon";
import { FiPlus as PlusIcon } from "react-icons/fi";

export const PropertiesPresentation = (props: PresentationProps) => {
  const screen = translations.screens.properties;
  const {
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    propertiesData,
    pagination,
    onClickProperty,
    onClickCreateJob,
    onClickAddProperty,
  } = props;
  const {
    currentPage,
    itemsPerPage,
    totalPages,
    isNextDisabled,
    isPrevDisabled,
    onChangeItemsPerPage,
    onClickPrev,
    onClickNext,
  } = pagination;

  const { properties } = propertiesData || {};

  return (
    <Layout>
      <Portal containerRef={pageTitleRef}>{t<string>(screen.pageTitle)}</Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{t<string>(screen.breadcrumbs.page)}</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>
      <FullPageContent>
        <Flex flex={1} flexDirection="column">
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Text fontSize="lg" fontWeight={700}>
              {t<string>(screen.pageTitle)}
            </Text>
            <Button
              colorScheme="blue"
              leftIcon={<PlusIcon />}
              size="sm"
              onClick={onClickAddProperty}
            >
              {t<string>(screen.add)}
            </Button>
          </Flex>
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}

          <br />
          {!isLoading && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>{t<string>(screen.table.property)}</Th>
                    <Th textAlign="center">
                      {t<string>(screen.table.scheduled)}
                    </Th>
                    <Th textAlign="center">
                      {t<string>(screen.table.inProgress)}
                    </Th>
                    <Th textAlign="center">
                      {t<string>(screen.table.completed)}
                    </Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {(properties || []).map((property) => (
                    <Tr
                      key={property.propertyId}
                      _hover={{
                        backgroundColor: "gray.50",
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Td
                        fontWeight={500}
                        onClick={onClickProperty(property.propertyId)}
                      >
                        <Flex alignItems="center" width="250px">
                          <PropertyIcon propertyUse={property.propertyUse} />
                          <Flex ml={2} whiteSpace="break-spaces">
                            {getAddressCopy(property)}
                          </Flex>
                        </Flex>
                      </Td>
                      <Td
                        textAlign="center"
                        onClick={onClickProperty(property.propertyId)}
                      >
                        {property.scheduledJobsCount}
                      </Td>
                      <Td
                        textAlign="center"
                        onClick={onClickProperty(property.propertyId)}
                      >
                        {property.inProgressJobsCount}
                      </Td>
                      <Td
                        textAlign="center"
                        onClick={onClickProperty(property.propertyId)}
                      >
                        {property.completedJobsCount}
                      </Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            aria-label="Options"
                            as={IconButton}
                            icon={<DotsIcon />}
                            variant="ghost"
                          />
                          <MenuList>
                            <MenuItem
                              onClick={onClickProperty(property.propertyId)}
                            >
                              {t<string>(screen.viewProperty)}
                            </MenuItem>
                            <MenuItem
                              onClick={onClickCreateJob(property.propertyId)}
                            >
                              {t<string>(screen.createNewJob)}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && !(properties || []).length && (
            <Flex justifyContent="center" mt={4}>
              <Text fontWeight={500}>{t<string>(screen.noJobs)}</Text>
            </Flex>
          )}
        </Flex>
        <Pagination
          currentPage={currentPage}
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
          itemsPerPage={itemsPerPage}
          mt={4}
          totalPages={totalPages}
          onChangeItemsPerPage={onChangeItemsPerPage}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
        />
      </FullPageContent>
    </Layout>
  );
};
