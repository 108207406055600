import React from "react";
import { Flex } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { CreatePropertySteps } from "../../UpsertProperty.types";

type Props = {
  currentStep: CreatePropertySteps;
};
export const SideMenu = (props: Props) => {
  const { currentStep } = props;
  const screen = translations.screens.upsertProperty.menu;
  return (
    <Flex flexDirection={["row", "row", "row", "column"]} gap="10px">
      <Flex
        color={
          currentStep === CreatePropertySteps.PropertyAddress
            ? "gray.800"
            : "gray.400"
        }
        fontSize="sm"
        fontWeight={
          currentStep === CreatePropertySteps.PropertyAddress ? 600 : 400
        }
        mt={4}
      >
        {t<string>(screen.propertyAddress)}
      </Flex>
      <Flex
        color={
          currentStep === CreatePropertySteps.PropertyUse
            ? "gray.800"
            : "gray.400"
        }
        fontSize="sm"
        fontWeight={currentStep === CreatePropertySteps.PropertyUse ? 600 : 400}
        mt={4}
      >
        {t<string>(screen.propertyUse)}
      </Flex>
      <Flex
        color={
          currentStep === CreatePropertySteps.PropertyInformation
            ? "gray.800"
            : "gray.400"
        }
        fontSize="sm"
        fontWeight={
          currentStep === CreatePropertySteps.PropertyInformation ? 600 : 400
        }
        mt={4}
      >
        {t<string>(screen.propertyInformation)}
      </Flex>
      <Flex
        color={
          currentStep === CreatePropertySteps.PropertyContacts
            ? "gray.800"
            : "gray.400"
        }
        fontSize="sm"
        fontWeight={
          currentStep === CreatePropertySteps.PropertyContacts ? 600 : 400
        }
        mt={4}
      >
        {t<string>(screen.propertyContacts)}
      </Flex>
    </Flex>
  );
};
