import React, { useCallback, useContext, useState } from "react";
import { UpsertPropertyPresentation } from "./UpsertProperty.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useStatics } from "../../api/modules/Statics";
import { useParams } from "react-router-dom";
import { CreatePropertySteps, PropertyType } from "./UpsertProperty.types";
import { useCreateProperty } from "../../api/modules/CreateProperty";

export const UpsertProperty = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { mutateAsync: handleCreatePropertyRequest, isLoading } =
    useCreateProperty();
  const { propertyId } = useParams();
  const isEditMode = Boolean(propertyId);
  const [currentStep, setCurrentStep] = useState(
    CreatePropertySteps.PropertyAddress
  );
  const handleSetCurrentStep = useCallback(
    (step: CreatePropertySteps) => {
      setCurrentStep(step);
    },
    [setCurrentStep]
  );

  const [propertyData, setPropertyData] = useState<Partial<PropertyType>>({});

  const handlePreserveData = useCallback(
    async (updatedData: Partial<PropertyType>, shouldSendRequest?: boolean) => {
      setPropertyData((currentPropertyData) => ({
        ...currentPropertyData,
        ...updatedData,
      }));
      if (shouldSendRequest) {
        await handleCreatePropertyRequest({
          ...propertyData,
          ...updatedData,
        });
      }
    },
    [setPropertyData, handleCreatePropertyRequest, propertyData]
  );

  return (
    <UpsertPropertyPresentation
      breadcrumbsRef={breadcrumbsRef}
      currentStep={currentStep}
      isCreating={isLoading}
      isEditMode={isEditMode}
      isLoading={isLoadingStatics}
      pageTitleRef={pageTitleRef}
      persistedPropertyData={propertyData}
      statics={staticsData}
      onPreserveData={handlePreserveData}
      onSetCurrentStep={handleSetCurrentStep}
    />
  );
};
