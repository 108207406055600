import React from "react";
import { Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { Props } from "./ContactInformation.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { PatternFormat } from "react-number-format";
import { PHONE_PATTERN } from "../../../../constants/global";

export const ContactInformation = (props: Props) => {
  const screen = translations.screens.user;
  const { data } = props;
  const { phoneNumber, email } = data || {};

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          <Tr>
            <Td fontWeight={500} paddingLeft="0" width="250px">
              {t<string>(screen.table.phone)}
            </Td>
            <Td color="gray.500">
              <PatternFormat
                displayType="text"
                format={PHONE_PATTERN}
                value={phoneNumber}
              />
            </Td>
          </Tr>
          <Tr>
            <Td fontWeight={500} maxWidth="300px" paddingLeft="0">
              {t<string>(screen.table.email)}
            </Td>
            <Td color="gray.500">{email || "-"}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
