import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Text,
} from "@chakra-ui/react";
import { PresentationProps } from "./AddressForm.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Routing } from "../../../../constants/routing";
import { Link } from "react-router-dom";

export const AddressFormPresentation = (props: PresentationProps) => {
  const screen = translations.screens.upsertProperty.addressDetails;
  const { isValid, errors, register, onSubmit } = props;
  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column" maxWidth={["100%", "100%", "100%", "65%"]}>
        <Text fontSize="lg" fontWeight={700}>
          {t<string>(screen.title)}
        </Text>
        <Flex
          alignItems="flex-start"
          alignSelf="center"
          flexDirection={["column", "column", "column", "row"]}
          flexGrow={1}
          flexWrap="wrap"
          gap="10px"
          justifyContent="center"
          mt={3}
          width="100%"
        >
          <Flex
            alignItems="flex-start"
            alignSelf="center"
            flexDirection="column"
            flexGrow={1}
            mt={4}
            width="100%"
          >
            <FormControl
              isInvalid={Boolean(errors.projectAddressStreet?.message)}
            >
              <Input
                id="projectAddressStreet"
                placeholder={t<string>(screen.street)}
                {...register("projectAddressStreet", {
                  required: "Value is required",
                })}
              />
              <FormErrorMessage>
                {errors.projectAddressStreet &&
                  errors.projectAddressStreet.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex
            alignItems="flex-start"
            alignSelf="center"
            flexDirection="column"
            flexGrow={1}
            mt={4}
            width={["100%", "100%", "100%", "45%"]}
          >
            <FormControl
              isInvalid={Boolean(errors.projectAddressCity?.message)}
            >
              <Input
                id="projectAddressCity"
                placeholder={t<string>(screen.city)}
                {...register("projectAddressCity", {
                  required: "Value is required",
                })}
              />
              <FormErrorMessage>
                {errors.projectAddressCity && errors.projectAddressCity.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex
            alignItems="flex-start"
            alignSelf="center"
            flexDirection="column"
            flexGrow={1}
            mt={4}
            width={["100%", "100%", "100%", "45%"]}
          >
            <FormControl
              isInvalid={Boolean(errors.projectAddressState?.message)}
            >
              <Input
                id="projectAddressState"
                placeholder={t<string>(screen.state)}
                {...register("projectAddressState", {
                  required: "Value is required",
                })}
              />
              <FormErrorMessage>
                {errors.projectAddressState &&
                  errors.projectAddressState.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex
            alignItems="flex-start"
            alignSelf="center"
            flexDirection="column"
            flexGrow={1}
            mt={4}
            width={["100%", "100%", "100%", "45%"]}
          >
            <FormControl isInvalid={Boolean(errors.projectAddressZip?.message)}>
              <Input
                id="projectAddressZip"
                placeholder={t<string>(screen.zip)}
                type="number"
                {...register("projectAddressZip", {
                  required: "Value is required",
                })}
              />
              <FormErrorMessage>
                {errors.projectAddressZip && errors.projectAddressZip.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt={7}>
          <Link to={Routing.Properties}>
            <Button size="sm" type="button">
              {t<string>(screen.cancelLabel)}
            </Button>
          </Link>
          <Button
            colorScheme="blue"
            isDisabled={!isValid}
            size="sm"
            type="submit"
          >
            {t<string>(screen.submitLabel)}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
