import React, { useCallback } from "react";
import { PropertyContactsFormPresentation } from "./PropertyContactsForm.presentation";
import { PropertyContactsFormType } from "./PropertyContactsForm.types";
import { useFieldArray, useForm } from "react-hook-form";
import { CreatePropertySteps, PropertyType } from "../../UpsertProperty.types";
import { defaultContactInfo } from "../../constants";

type Props = {
  onPreserveData: (
    data: Partial<PropertyType>,
    shouldSendRequest?: boolean
  ) => void;
  onSetCurrentStep: (step: CreatePropertySteps) => void;
  persistedPropertyData: Partial<PropertyType>;
  isCreating: boolean;
};
export const PropertyContactsForm = (props: Props) => {
  const {
    onPreserveData,
    onSetCurrentStep,
    persistedPropertyData,
    isCreating,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { isValid, touchedFields, errors },
    control,
  } = useForm<PropertyContactsFormType>({
    mode: "onChange",
    defaultValues: {
      propertyContacts: persistedPropertyData["propertyContacts"] || [
        {
          ...defaultContactInfo,
        },
      ],
    },
  });
  const onSubmit = handleSubmit((data) => {
    const payload = {
      propertyContacts: data.propertyContacts,
    };
    onPreserveData(payload, true);
  });

  const onCancel = useCallback(() => {
    onSetCurrentStep(CreatePropertySteps.PropertyInformation);
  }, [onSetCurrentStep]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "propertyContacts",
  });

  const handleRemoveContact = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );
  const handleAddContact = useCallback(() => {
    append({ ...defaultContactInfo });
  }, [append]);

  return (
    <PropertyContactsFormPresentation
      contacts={fields}
      control={control}
      errors={errors}
      isLoading={isCreating}
      isValid={isValid}
      register={register}
      touchedFields={touchedFields}
      onAddContact={handleAddContact}
      onCancel={onCancel}
      onRemoveContact={handleRemoveContact}
      onSubmit={onSubmit}
    />
  );
};
