import React from "react";
import { Box, Drawer, DrawerContent, useDisclosure } from "@chakra-ui/react";
import MenuContent from "../MenuContent";
import { Props } from "./Layout.types";
import Navigation from "../Navigation";

export const Layout = ({ children }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box bg="gray.50" minH="100vh">
      <MenuContent display={{ base: "none", md: "flex" }} onClose={onClose} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        returnFocusOnClose={false}
        onClose={onClose}
        onOverlayClick={onClose}
      >
        <DrawerContent>
          <MenuContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Navigation onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
};
