import React, { useContext, useMemo } from "react";
import { UserPresentation } from "./User.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useStatics } from "../../api/modules/Statics";
import { useUserById } from "../../api/modules/UserById";
import { useParams } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import { useCompany } from "../../api/modules/Company";

export const User = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const {
    isOpen: isContactModalOpen,
    onOpen: onContactModalOpen,
    onClose: onContactModalClose,
  } = useDisclosure();
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { userId } = useParams();
  const { data } = useUserById(userId!);
  const { data: companyData } = useCompany(data?.companyId);
  const serviceOptions = useMemo(
    () =>
      (staticsData?.jobTypes || []).reduce<{ label: string; value: number }[]>(
        (acc, service) => {
          if (companyData?.companyJobTypes.includes(service.id)) {
            acc.push({ value: service.id, label: service.title });
          }

          (service.relatedJobTypes || []).forEach((relatedJob) => {
            if (companyData?.companyJobTypes.includes(relatedJob.id)) {
              acc.push({ value: relatedJob.id, label: relatedJob.title });
            }
          });
          return acc;
        },
        []
      ),
    [staticsData, companyData]
  );

  return (
    <UserPresentation
      breadcrumbsRef={breadcrumbsRef}
      data={data}
      isContactModalOpen={isContactModalOpen}
      isLoading={isLoadingStatics}
      pageTitleRef={pageTitleRef}
      serviceOptions={serviceOptions}
      statics={staticsData}
      onContactModalClose={onContactModalClose}
      onContactModalOpen={onContactModalOpen}
    />
  );
};
