import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getJobs } from "./getJobs";
import { TJobs } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";

type Props = {
  currentPage: number;
  itemsPerPage: number;
};

export const useJobs = ({ currentPage, itemsPerPage }: Props) => {
  const toast = useToast();
  return useQuery<TJobs>(
    Queries.Jobs(currentPage, itemsPerPage),
    getJobs(currentPage, itemsPerPage),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.jobs_error),
          status: "error",
          position: "top-right",
        });
      },
    }
  );
};
