import React from "react";
import Layout from "../../components/Layout";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Icon,
  Input,
  Link,
  Portal,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import {
  FaCalendarAlt as CalendarIcon,
  FaBriefcase as CaseIcon,
  FaClipboardCheck as CheckIcon,
} from "react-icons/fa";
import { t } from "i18next";
import { Routing } from "../../constants/routing";
import { translations } from "../../i18n/translations";
import { PresentationProps } from "./Property.types";
import { Link as RouterLink } from "react-router-dom";
import FullPageContent from "../../components/FullPageContent";
import { getAddressCopy } from "../../utils/Property/getAddressCopy";
import { PropertyIcon } from "../Properties/components/PropertyIcon";
import Feature from "../../components/Feature";
import DatePicker from "../../components/DatePicker";
import { DATE_FORMAT, PHONE_PATTERN } from "../../constants/global";
import { parse } from "date-fns";
import { PatternFormat } from "react-number-format";

export const PropertyPresentation = (props: PresentationProps) => {
  const screen = translations.screens.property;
  const {
    formData,
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    property,
    commercialCapacityOptions,
    residentialCapacityOptions,
    commercialSizeLabel,
    residentialSizeLabel,

    isEditMode,
    isUpdatingProperty,
    onSetEditMode,
    onCancelEditMode,
    onSaveProperty,
    onEditField,
    onEditContactField,
    onCreateJob,
    onChangeRadioBoolean,
    onChangeDate,
    onAddContact,
    onRemoveContact,
  } = props;

  return (
    <Layout>
      <Portal containerRef={pageTitleRef}>{t<string>(screen.pageTitle)}</Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={Routing.Properties}>
              {t<string>(screen.breadcrumbs.properties)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{property ? getAddressCopy(property) : "Loading..."}</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>
      <FullPageContent>
        <Link
          _focus={{ boxShadow: "none" }}
          as={RouterLink}
          fontSize={12}
          fontWeight={500}
          style={{ textDecoration: "none" }}
          to={Routing.Properties}
        >
          {t<string>(screen.backToPropertys)}
        </Link>
        {isLoading && (
          <Flex justifyContent="center" mt={4}>
            <Spinner />
          </Flex>
        )}
        {!isLoading && (
          <>
            <Text fontSize="xl" fontWeight={500}>
              {property && (
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex alignItems="center">
                    <Box mr={3}>
                      <PropertyIcon
                        fontSize="35px"
                        height="60px"
                        propertyUse={property.propertyUse}
                        width="60px"
                      />
                    </Box>
                    {getAddressCopy(property)}
                  </Flex>
                  <Flex>
                    <Button
                      colorScheme="blue"
                      leftIcon={<PlusIcon />}
                      size="sm"
                      onClick={onCreateJob}
                    >
                      {t<string>(screen.createJob)}
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Text>
            <br />
            <Divider />
            <br />
            <Flex flexDirection={["column", "column", "row"]} gap={4} mb={3}>
              <Flex flexBasis={["100%", "100%", "33%"]}>
                <Feature>
                  <Flex alignItems="center">
                    <Flex flexDirection="column" flexGrow={1}>
                      <Box
                        color="gray.400"
                        fontSize="sm"
                        textTransform="uppercase"
                      >
                        {t<string>(screen.scheduledJobsCount)}
                      </Box>
                      <Box fontSize="xl" fontWeight={600}>
                        {property?.scheduledJobsCount}
                      </Box>
                    </Flex>
                    <Flex
                      alignItems="center"
                      bgColor="blue.100"
                      borderRadius="50%"
                      fontSize="2xl"
                      height="50px"
                      justifyContent="center"
                      p={3}
                      width="50px"
                    >
                      <Icon as={CalendarIcon} color="blue.600" />
                    </Flex>
                  </Flex>
                </Feature>
              </Flex>
              <Flex flexBasis={["100%", "100%", "33%"]}>
                <Feature>
                  <Flex alignItems="center">
                    <Flex flexDirection="column" flexGrow={1}>
                      <Box
                        color="gray.400"
                        fontSize="sm"
                        textTransform="uppercase"
                      >
                        {t<string>(screen.completedJobsCount)}
                      </Box>
                      <Box fontSize="xl" fontWeight={600}>
                        {property?.completedJobsCount}
                      </Box>
                    </Flex>
                    <Flex
                      alignItems="center"
                      bgColor="green.100"
                      borderRadius="50%"
                      fontSize="2xl"
                      height="50px"
                      justifyContent="center"
                      p={3}
                      width="50px"
                    >
                      <Icon as={CheckIcon} color="green.600" />
                    </Flex>
                  </Flex>
                </Feature>
              </Flex>
              <Flex flexBasis={["100%", "100%", "33%"]}>
                <Feature>
                  <Flex alignItems="center">
                    <Flex flexDirection="column" flexGrow={1}>
                      <Box
                        color="gray.400"
                        fontSize="sm"
                        textTransform="uppercase"
                      >
                        {t<string>(screen.inProgressJobsCount)}
                      </Box>
                      <Box fontSize="xl" fontWeight={600}>
                        {property?.inProgressJobsCount}
                      </Box>
                    </Flex>
                    <Flex
                      alignItems="center"
                      bgColor="purple.100"
                      borderRadius="50%"
                      fontSize="2xl"
                      height="50px"
                      justifyContent="center"
                      p={3}
                      width="50px"
                    >
                      <Icon as={CaseIcon} color="purple.600" />
                    </Flex>
                  </Flex>
                </Feature>
              </Flex>
            </Flex>
            <br />
            <Divider />
            <br />
            <Tabs>
              <TabList>
                <Tab>{t<string>(screen.propertyDetails)}</Tab>
                <Tab>{t<string>(screen.propertyProfile)}</Tab>
                <Tab>{t<string>(screen.jobHistory)}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <br />
                  <Flex flex={1} flexDirection="column">
                    <Flex alignItems="center" justifyContent="space-between">
                      <Text fontSize="lg" fontWeight={700}>
                        {t<string>(screen.propertyDetails)}
                      </Text>
                      <Flex justifyContent="flex-end">
                        {isEditMode && (
                          <>
                            <Button
                              colorScheme="blue"
                              isLoading={isUpdatingProperty}
                              size="sm"
                              variant="outline"
                              onClick={onCancelEditMode}
                            >
                              {t<string>(screen.cancel)}
                            </Button>
                            <Box ml={3}>
                              <Button
                                colorScheme="blue"
                                isLoading={isUpdatingProperty}
                                size="sm"
                                onClick={onSaveProperty}
                              >
                                {t<string>(screen.save)}
                              </Button>
                            </Box>
                          </>
                        )}
                        {!isEditMode && (
                          <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={onSetEditMode}
                          >
                            {t<string>(screen.edit)}
                          </Button>
                        )}
                      </Flex>
                    </Flex>

                    <TableContainer maxWidth="100%" width="100%">
                      <Table variant="simple">
                        <Tbody fontSize="sm">
                          <Tr>
                            <Td color="gray.500" width="25%">
                              {t<string>(screen.table.propertyUse)}
                            </Td>
                            <Td>
                              <Flex flexDirection="column">
                                <Text fontWeight={500}>
                                  {t<string>(
                                    screen.table.propertyUseDescription
                                  )}
                                </Text>
                                {isEditMode ? (
                                  <RadioGroup
                                    as={Flex}
                                    defaultValue={(+Boolean(
                                      formData?.propertyUse
                                    )).toString()}
                                    flexDirection="column"
                                    onChange={onChangeRadioBoolean(
                                      "propertyUse"
                                    )}
                                  >
                                    <Radio name="propertyUse" value="1">
                                      {t<string>(screen.table.yes)}
                                    </Radio>
                                    <Radio name="propertyUse" value="0">
                                      {t<string>(screen.table.no)}
                                    </Radio>
                                  </RadioGroup>
                                ) : (
                                  <Box>
                                    {property?.propertyUse
                                      ? t<string>(screen.table.yes)
                                      : t<string>(screen.table.no)}
                                  </Box>
                                )}
                              </Flex>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <br />
                    {!property?.propertyUse && (
                      <>
                        <Text fontSize="lg" fontWeight={700}>
                          {t<string>(screen.residentialDetails)}
                        </Text>
                        <TableContainer maxWidth="100%" width="100%">
                          <Table variant="simple">
                            <Tbody fontSize="sm">
                              <Tr>
                                <Td color="gray.500" width="25%">
                                  {t<string>(
                                    screen.table.residential.lastDateOfOccupancy
                                  )}
                                </Td>
                                <Td>
                                  {isEditMode ? (
                                    <DatePicker
                                      id="residentialInfoLastDateOfOccupancy"
                                      name="residentialInfoLastDateOfOccupancy"
                                      selected={
                                        formData.residentialInfoLastDateOfOccupancy
                                          ? parse(
                                              formData.residentialInfoLastDateOfOccupancy,
                                              DATE_FORMAT,
                                              new Date()
                                            )
                                          : null
                                      }
                                      onChange={onChangeDate(
                                        "residentialInfoLastDateOfOccupancy"
                                      )}
                                    />
                                  ) : (
                                    property?.residentialInfoLastDateOfOccupancy ||
                                    "-"
                                  )}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td color="gray.500" width="25%">
                                  {t<string>(
                                    screen.table.residential.residentialCapacity
                                  )}
                                </Td>
                                <Td>
                                  {isEditMode ? (
                                    <Select
                                      value={formData.residentialCapacity || ""}
                                      onChange={onEditField(
                                        "residentialCapacity"
                                      )}
                                    >
                                      {residentialCapacityOptions.map(
                                        ({ label, value }) => (
                                          <option key={value} value={value}>
                                            {label}
                                          </option>
                                        )
                                      )}
                                    </Select>
                                  ) : (
                                    residentialCapacityOptions.find(
                                      (o) =>
                                        parseInt(o.value.toString()) ===
                                        parseInt(
                                          (
                                            property?.residentialCapacity || ""
                                          ).toString()
                                        )
                                    )?.label || "-"
                                  )}
                                </Td>
                              </Tr>
                              {(isEditMode && formData?.residentialCapacity) ||
                              (!isEditMode && property?.residentialCapacity) ? (
                                <Tr>
                                  <Td color="gray.500" width="25%">
                                    {t<string>(
                                      screen.table.residential[
                                        residentialSizeLabel
                                      ]
                                    )}
                                  </Td>
                                  <Td>
                                    {isEditMode ? (
                                      <Input
                                        disabled={isUpdatingProperty}
                                        value={
                                          formData.residentialInfoGdpRange || ""
                                        }
                                        onChange={onEditField(
                                          "residentialInfoGdpRange"
                                        )}
                                      />
                                    ) : (
                                      property?.residentialInfoGdpRange || "-"
                                    )}
                                  </Td>
                                </Tr>
                              ) : null}
                              <Tr>
                                <Td color="gray.500" width="25%">
                                  {t<string>(
                                    screen.table.residential
                                      .numberOfCurrentResidents
                                  )}
                                </Td>
                                <Td>
                                  {isEditMode ? (
                                    <Input
                                      disabled={isUpdatingProperty}
                                      value={
                                        formData.residentialInfoNumberOfCurrentResidents
                                      }
                                      onChange={onEditField(
                                        "residentialInfoNumberOfCurrentResidents"
                                      )}
                                    />
                                  ) : (
                                    property?.residentialInfoNumberOfCurrentResidents ||
                                    "-"
                                  )}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                    {property?.propertyUse && (
                      <>
                        <br />
                        <Text fontSize="lg" fontWeight={700}>
                          {t<string>(screen.commercialDetails)}
                        </Text>
                        <TableContainer maxWidth="100%" width="100%">
                          <Table variant="simple">
                            <Tbody fontSize="sm">
                              <Tr>
                                <Td color="gray.500" width="25%">
                                  {t<string>(
                                    screen.table.commercial.lastDateOfUse
                                  )}
                                </Td>
                                <Td>
                                  {isEditMode ? (
                                    <DatePicker
                                      id="commercialInfoLastDateOfUse"
                                      name="commercialInfoLastDateOfUse"
                                      selected={
                                        formData.commercialInfoLastDateOfUse
                                          ? parse(
                                              formData.commercialInfoLastDateOfUse,
                                              DATE_FORMAT,
                                              new Date()
                                            )
                                          : null
                                      }
                                      onChange={onChangeDate(
                                        "commercialInfoLastDateOfUse"
                                      )}
                                    />
                                  ) : (
                                    property?.commercialInfoLastDateOfUse || "-"
                                  )}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td color="gray.500" width="25%">
                                  {t<string>(
                                    screen.table.commercial.typeOfEstablishment
                                  )}
                                </Td>
                                <Td>
                                  {isEditMode ? (
                                    <Input
                                      disabled={isUpdatingProperty}
                                      value={
                                        formData.commercialInfoTypeOfEstablishment
                                      }
                                      onChange={onEditField(
                                        "commercialInfoTypeOfEstablishment"
                                      )}
                                    />
                                  ) : (
                                    property?.commercialInfoTypeOfEstablishment ||
                                    "-"
                                  )}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td color="gray.500" width="25%">
                                  {t<string>(
                                    screen.table.commercial.commercialCapacity
                                  )}
                                </Td>
                                <Td>
                                  {isEditMode ? (
                                    <Select
                                      value={formData.commercialCapacity ?? 0}
                                      onChange={onEditField(
                                        "commercialCapacity"
                                      )}
                                    >
                                      {commercialCapacityOptions.map(
                                        ({ label, value }) => (
                                          <option key={value} value={value}>
                                            {label}
                                          </option>
                                        )
                                      )}
                                    </Select>
                                  ) : (
                                    commercialCapacityOptions.find(
                                      (o) =>
                                        parseInt(o.value.toString()) ===
                                        parseInt(
                                          (
                                            property?.commercialCapacity || ""
                                          ).toString()
                                        )
                                    )?.label || "-"
                                  )}
                                </Td>
                              </Tr>
                              {(isEditMode && formData?.commercialCapacity) ||
                              (!isEditMode && property?.commercialCapacity) ? (
                                <Tr>
                                  <Td color="gray.500" width="25%">
                                    {t<string>(
                                      screen.table.commercial[
                                        commercialSizeLabel
                                      ]
                                    )}
                                  </Td>
                                  <Td>
                                    {isEditMode ? (
                                      <Input
                                        disabled={isUpdatingProperty}
                                        value={
                                          formData.commercialInfoGdpRange || ""
                                        }
                                        onChange={onEditField(
                                          "commercialInfoGdpRange"
                                        )}
                                      />
                                    ) : (
                                      property?.commercialInfoGdpRange || "-"
                                    )}
                                  </Td>
                                </Tr>
                              ) : null}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </>
                    )}
                    <br />
                    <>
                      <Flex alignItems="center" gap="15px">
                        <Text fontSize="lg" fontWeight={700}>
                          {t<string>(screen.propertyContacts)}
                        </Text>
                        {isEditMode ? (
                          <Button size="xs" onClick={onAddContact}>
                            {t<string>(screen.addContact)}
                          </Button>
                        ) : null}
                      </Flex>
                      {(
                        (isEditMode
                          ? formData.propertyContacts
                          : property?.propertyContacts) || []
                      ).map((contact, i) => (
                        <Box key={i}>
                          <Flex alignItems="center" gap="15px" mt={3}>
                            <Text fontSize="sm" fontWeight={500}>
                              {t<string>(screen.table.propertyContacts.title)}{" "}
                              {i + 1}
                            </Text>
                            {isEditMode && Boolean(i) ? (
                              <Button size="xs" onClick={onRemoveContact(i)}>
                                {t<string>(screen.removeContact)}
                              </Button>
                            ) : null}
                          </Flex>
                          <TableContainer maxWidth="100%" width="100%">
                            <Table variant="simple">
                              <Tbody fontSize="sm">
                                <Tr>
                                  <Td color="gray.500" width="25%">
                                    {t<string>(
                                      screen.table.propertyContacts.firstName
                                    )}
                                  </Td>
                                  <Td color="gray.500" width="25%">
                                    {isEditMode ? (
                                      <Input
                                        disabled={isUpdatingProperty}
                                        value={
                                          formData.propertyContacts?.[i]
                                            .firstName || ""
                                        }
                                        onChange={onEditContactField(
                                          "firstName",
                                          i
                                        )}
                                      />
                                    ) : (
                                      contact.firstName || "-"
                                    )}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td color="gray.500" width="25%">
                                    {t<string>(
                                      screen.table.propertyContacts.lastName
                                    )}
                                  </Td>
                                  <Td color="gray.500" width="25%">
                                    {isEditMode ? (
                                      <Input
                                        disabled={isUpdatingProperty}
                                        value={
                                          formData.propertyContacts?.[i]
                                            .lastName || ""
                                        }
                                        onChange={onEditContactField(
                                          "lastName",
                                          i
                                        )}
                                      />
                                    ) : (
                                      contact.lastName || "-"
                                    )}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td color="gray.500" width="25%">
                                    {t<string>(
                                      screen.table.propertyContacts.email
                                    )}
                                  </Td>
                                  <Td color="gray.500" width="25%">
                                    {isEditMode ? (
                                      <Input
                                        disabled={isUpdatingProperty}
                                        value={
                                          formData.propertyContacts?.[i]
                                            .emailAddress || ""
                                        }
                                        onChange={onEditContactField(
                                          "emailAddress",
                                          i
                                        )}
                                      />
                                    ) : (
                                      contact.emailAddress || "-"
                                    )}
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td color="gray.500" width="25%">
                                    {t<string>(
                                      screen.table.propertyContacts.phone
                                    )}
                                  </Td>
                                  <Td color="gray.500" width="25%">
                                    {isEditMode ? (
                                      <PatternFormat
                                        customInput={Input}
                                        defaultValue={
                                          formData.propertyContacts?.[i]
                                            .phoneNumber || ""
                                        }
                                        format={PHONE_PATTERN}
                                        onValueChange={(v) => {
                                          onEditContactField(
                                            "phoneNumber",
                                            i
                                          )({
                                            target: { value: v.value },
                                          } as any);
                                        }}
                                      />
                                    ) : (
                                      contact.phoneNumber || "-"
                                    )}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Box>
                      ))}
                    </>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <i>No data available</i>
                </TabPanel>
                <TabPanel>
                  <i>No data available</i>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </FullPageContent>
    </Layout>
  );
};
