import React, { useCallback } from "react";
import { LoginPresentation } from "./Login.presentation";
import { useAuthorizeUser } from "../../api/modules/AuthorizeUser";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";
import { SignInFormType } from "./Login.types";
import { CredentialResponse } from "google-one-tap";
import { useInitializeGoogleAuth } from "../../hooks/useInitializeGoogleAuth";
import { useInitializeAppleAuth } from "../../hooks/useInitializeAppleAuth";
import { useGoogleSignIn } from "../../api/modules/GoogleSignIn";
import { useAppleSignIn } from "../../api/modules/AppleSignIn";

export const Login = () => {
  const { mutateAsync: handleMutateEmailSignIn } = useAuthorizeUser();
  const { mutateAsync: handleMutateGoogleSignIn } = useGoogleSignIn();
  const { mutateAsync: handleMutateAppleSignIn } = useAppleSignIn();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm<SignInFormType>();

  const handleGoogleSignIn = useCallback(
    (response: CredentialResponse) => {
      if (!response.credential) return;
      handleMutateGoogleSignIn(
        { token: response.credential },
        {
          onSuccess: () => {
            navigate(Routing.Root);
          },
        }
      );
    },
    [navigate, handleMutateGoogleSignIn]
  );

  useInitializeGoogleAuth({
    callback: handleGoogleSignIn,
  });

  useInitializeAppleAuth();

  const onSubmit = handleSubmit(async (data) => {
    await handleMutateEmailSignIn(
      {
        email: data.email,
        password: data.password,
      },
      {
        onSuccess: () => {
          navigate(Routing.Root);
        },
        onError: () => {
          reset(undefined, { keepValues: true });
        },
      }
    );
  });

  const handleAppleSignIn = useCallback(async () => {
    const response = await AppleID.auth.signIn();
    if (!response?.authorization?.id_token) return;
    handleMutateAppleSignIn(
      { token: response.authorization.id_token },
      {
        onSuccess: () => {
          navigate(Routing.Root);
        },
      }
    );
  }, [navigate, handleMutateAppleSignIn]);

  return (
    <LoginPresentation
      errors={errors}
      isSubmitting={isSubmitting}
      isValid={isValid}
      register={register}
      onAppleSignIn={handleAppleSignIn}
      onSubmit={onSubmit}
    />
  );
};
