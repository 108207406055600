import React from "react";
import { Text } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { ContactInformation } from "../ContactInformation";
import { Props } from "./PersonalDetails.types";

export const PersonalDetailsTab = (props: Props) => {
  const { data } = props;
  const screen = translations.screens.user;

  return (
    <>
      <br />
      <Text fontSize="lg" fontWeight={600}>
        {t<string>(screen.contactInformation)}
      </Text>
      <br />
      <ContactInformation data={data} />
    </>
  );
};
