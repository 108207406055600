import { Endpoints } from "../../../constants/endpoints";
import { TPropertiesRaw } from "./types";
import client from "../../client";

export const getProperties =
  (currentPage: number, itemsPerPage: number) => async () => {
    const { data } = await client.get<TPropertiesRaw>(
      Endpoints.getProperties(currentPage, itemsPerPage)
    );
    return data;
  };
