import React, { useCallback, useContext, useEffect } from "react";
import { PropertiesPresentation } from "./Properties.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useStatics } from "../../api/modules/Statics";
import { usePagination } from "../../hooks/usePagination";
import { useNavigate } from "react-router-dom";
import { useProperties } from "../../api/modules/Properties";
import { getCreateJobLink } from "../../utils/getCreateJobLink";
import { Routing } from "../../constants/routing";

export const Properties = () => {
  const navigate = useNavigate();
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const pagination = usePagination();
  const { isLoading: isLoadingJobs, data: propertiesData } = useProperties({
    itemsPerPage: pagination.itemsPerPage,
    currentPage: pagination.currentPage,
  });
  const handleAddProperty = useCallback(() => {
    navigate(Routing.AddProperty);
  }, [navigate]);

  useEffect(() => {
    pagination.setTotalItems(propertiesData?.totalPropertiesCount ?? 0);
  }, [pagination, propertiesData?.totalPropertiesCount]);

  const handleClickProperty = useCallback(
    (propertyId: string | number) => () => {
      navigate(`/property/${propertyId}`);
    },
    [navigate]
  );
  const handleClickCreateJob = useCallback(
    (propertyId: string | number) => () => {
      location.href = getCreateJobLink(propertyId);
    },
    []
  );

  return (
    <PropertiesPresentation
      breadcrumbsRef={breadcrumbsRef}
      isLoading={isLoadingJobs || isLoadingStatics}
      pageTitleRef={pageTitleRef}
      pagination={pagination}
      propertiesData={propertiesData}
      statics={staticsData}
      onClickAddProperty={handleAddProperty}
      onClickCreateJob={handleClickCreateJob}
      onClickProperty={handleClickProperty}
    />
  );
};
