import { extendTheme } from "@chakra-ui/react";
import { Table } from "./components/table";
import { Tag } from "./components/tag";
import { Tabs } from "./components/tabs";
import { blue } from "./colors/blue";
import { orange } from "./colors/orange";

export const theme = extendTheme({
  components: {
    Table,
    Tag,
    Tabs,
  },
  colors: {
    blue,
    orange,
  },
});
