export const getSizeFieldPlaceholder = (value?: string | number | null) => {
  switch (parseInt((value || 0).toString() ?? "0")) {
    case 1:
      return "gdp";
    case 2:
      return "gallons";
    default:
      return "bedrooms";
  }
};
