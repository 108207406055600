import React from "react";
import { AddressFormPresentation } from "./AddressForm.presentation";
import { AddressFormType } from "./AddressForm.types";
import { useForm } from "react-hook-form";
import { CreatePropertySteps, PropertyType } from "../../UpsertProperty.types";

type Props = {
  onPreserveData: (data: AddressFormType) => void;
  onSetCurrentStep: (step: CreatePropertySteps) => void;
  persistedPropertyData: Partial<PropertyType>;
};
export const AddressForm = (props: Props) => {
  const { onPreserveData, onSetCurrentStep, persistedPropertyData } = props;
  const {
    register,
    handleSubmit,
    formState: { isValid, touchedFields, errors },
    control,
  } = useForm<AddressFormType>({
    mode: "onChange",
    defaultValues: {
      projectAddressStreet: persistedPropertyData?.projectAddressStreet || "",
      projectAddressCity: persistedPropertyData?.projectAddressCity || "",
      projectAddressState: persistedPropertyData?.projectAddressState || "",
      projectAddressZip: persistedPropertyData?.projectAddressZip || "",
    },
  });
  const onSubmit = handleSubmit((data) => {
    onPreserveData(data);
    onSetCurrentStep(CreatePropertySteps.PropertyUse);
  });

  return (
    <AddressFormPresentation
      control={control}
      errors={errors}
      isValid={isValid}
      register={register}
      touchedFields={touchedFields}
      onSubmit={onSubmit}
    />
  );
};
