import React, { useCallback } from "react";
import { CommercialInformationFormPresentation } from "./CommercialInformationForm.presentation";
import { CommercialInformationFormType } from "./CommercialInformationForm.types";
import { useForm } from "react-hook-form";
import { CreatePropertySteps, PropertyType } from "../../UpsertProperty.types";
import { useStatics } from "../../../../api/modules/Statics";
import { format, parse } from "date-fns";
import { DATE_FORMAT } from "../../../../constants/global";

type Props = {
  onPreserveData: (
    data: Partial<PropertyType>,
    shouldSendRequest?: boolean
  ) => void;
  onSetCurrentStep: (step: CreatePropertySteps) => void;
  persistedPropertyData: Partial<PropertyType>;
  isCreating: boolean;
};
export const CommercialInformationForm = (props: Props) => {
  const {
    onPreserveData,
    onSetCurrentStep,
    persistedPropertyData,
    isCreating,
  } = props;
  const { data: statics } = useStatics();
  const { commercialCapacityOptions } = statics || {};
  const defaultCommercialCapacity = Number(
    persistedPropertyData?.commercialCapacity || "1"
  ).toString();
  const {
    register,
    handleSubmit,
    formState: { isValid, touchedFields, errors },
    control,
    watch,
  } = useForm<CommercialInformationFormType>({
    mode: "onChange",
    defaultValues: {
      // @ts-expect-error
      commercialInfoLastDateOfUse: persistedPropertyData[
        "commercialInfoLastDateOfUse"
      ]
        ? parse(
            persistedPropertyData["commercialInfoLastDateOfUse"],
            DATE_FORMAT,
            new Date()
          )
        : undefined,
      commercialInfoTypeOfEstablishment:
        persistedPropertyData["commercialInfoTypeOfEstablishment"],
      commercialCapacity: defaultCommercialCapacity,
      commercialInfoGdpRange: persistedPropertyData["commercialInfoGdpRange"],
    },
  });
  const commercialCapacity = watch("commercialCapacity");
  const onSubmit = handleSubmit((data) => {
    const payload: Partial<PropertyType> = {
      commercialCapacity: data.commercialCapacity
        ? Number(data.commercialCapacity)
        : null,
      commercialInfoTypeOfEstablishment: data.commercialInfoTypeOfEstablishment,
      commercialInfoGdpRange: data.commercialInfoGdpRange,
      commercialInfoLastDateOfUse: data.commercialInfoLastDateOfUse
        ? format(
            data.commercialInfoLastDateOfUse as unknown as Date,
            DATE_FORMAT
          )
        : null,
    };
    onPreserveData(payload);
    onSetCurrentStep(CreatePropertySteps.PropertyContacts);
  });
  const onCancel = useCallback(() => {
    onSetCurrentStep(CreatePropertySteps.PropertyUse);
  }, [onSetCurrentStep]);
  return (
    <CommercialInformationFormPresentation
      commercialCapacity={(commercialCapacity || 0).toString()}
      commercialCapacityOptions={commercialCapacityOptions}
      control={control}
      defaultCommercialCapacity={defaultCommercialCapacity}
      errors={errors}
      isLoading={isCreating}
      isValid={isValid}
      register={register}
      touchedFields={touchedFields}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};
